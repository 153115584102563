import styled from "@emotion/styled";
import { motion } from "framer-motion";

import {
  IBoxStyles,
  IGridStyles,
  withBoxStyles,
  withGridStyles,
} from "~src/shared/helpers/sharedComponentProps";
import { IPipeTheme } from "~src/shared/theme/darkVariant";

export type IParagraphProps = {
  size?: "14px" | "10px";
  lineHeight?: 1 | 1.2 | 1.8 | 2.4;
  color?: keyof IPipeTheme["components"]["Text"];
  children?: React.ReactNode;
  style?: React.CSSProperties;
} & IGridStyles &
  IBoxStyles;

/**
 * @deprecated Use Text instead.
 */
export const OldParagraphUseTextInstead: React.FC<IParagraphProps> = styled(
  motion.p,
)<IParagraphProps>`
  ${(props) => withGridStyles(props)}
  ${(props) => withBoxStyles(props)}
  line-height: ${(props) => props.lineHeight ?? 1.8};
  font-size: ${(props) => props.size !== undefined && props.size};
  color: ${(props) => typeof props.color === "string" && props.theme.components.Text[props.color]};
`;
