/* eslint-disable react/style-prop-object */
import React from "react";

export const Recurly: React.FC = () => (
  <svg id="logosandtypes_com" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150.2">
    <path d="M0 .2h150v150H0V.2z" fill="none" />
    <g>
      <path
        d="M74.4 12.5c-34 0-61.6 27.6-61.6 61.6s27.6 61.6 61.6 61.6S136 108.1 136 74.1s-27.6-61.6-61.6-61.6zm13.4 96.9L75.3 89.3c-5.2-7.7-8.2-8.8-11.2-8.8v28.9h-14V71h22.2c10 0 14.2-6 14.2-13.9 0-5.4-3.1-11.9-11.9-11.9-10.4 0-10.5 8.3-10.5 8.3h8l-15 11.9-14-11.9h7c1.8-11.5 10-18.2 24.1-18.2 16 0 26.1 7 26.1 21.8 0 12-8.7 19.4-17.5 21.6 1.7 1.3 4.3 4.8 7.7 10.1l12.8 20.6H87.8z"
        fill="#713080"
      />
    </g>
  </svg>
);
