/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import NextLink, { LinkProps } from "next/link";
import React, { forwardRef } from "react";

/* TODO(md): Fix this when I get off Next.JS */
/* eslint-disable */

export interface ILinkProps extends LinkProps {
  children: React.ReactNode;
  newTab?: boolean; // newTab = default false
  style?: React.CSSProperties;
  cssStyles?: SerializedStyles;
  className?: string;
  ref?: any;
  onClick?: () => void;
}

// TODO: This ref isn't even being used wtf.

type IInternalLinkProps = { href?: any } & Omit<ILinkProps, "href">;

const InternalLink = forwardRef(
  // oh man thats disgusting
  (props: IInternalLinkProps, ref) => {
    return (
      <a
        className={props.className}
        href={props.href?.toString()}
        rel={props.href ? "noopener noreferrer" : ""}
        target={props.newTab === true ? "_blank" : undefined}
        css={{ ...linkStyles, ...props.cssStyles }}
        style={props.style}
        onClick={props.onClick}
      >
        {props.children}
      </a>
    );
  },
);
InternalLink.displayName = "ForwardRef(InternalLink)";

export const Link: React.FC<ILinkProps> = (props) => {
  const { href, as, ...remainingProps } = props;

  const isExternalLink = typeof href === "string" && href?.match(/^(http|https):\/\//i) !== null;

  if (!isExternalLink) {
    return (
      <NextLink href={href} as={as} passHref>
        <InternalLink {...remainingProps} />
      </NextLink>
    );
  }

  return <InternalLink href={href} {...remainingProps} />;
};

export const linkStyles = css`
  height: fit-content;
  min-width: fit-content;

  color: #0a8de5;

  text-decoration: none;
  cursor: pointer;
`;
