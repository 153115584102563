import React, { useMemo } from "react";

import { useFetchDataSourceRequirements } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";

import { useVendorDataSources } from "../../useVendorDataSources";
import { ConnectionBadge } from "../ConnectionBadge";

type IProps = {
  onClick?: () => void;
};

/**
 * Depending on the vendor's data sources, this component can ever be in 3 states:
 *
 * (a) Vendor hasn't connected any data sources => show "0 Connections"
 * (b) Some data sources are still being synced => show "N/M Connections", where:
 *        N = data sources currently syncing and
 *        M = total number of data sources connected + additionally requested data sources.
 * (c) N data sources are disconnected =>  show "N critical error(s)".
 */
export const DataSourcesBadge: React.FC<IProps> = (props) => {
  const { onClick } = props;

  const { data, loading } = useVendorDataSources();
  const { data: dataSourceRequirements, loading: requirementsLoading } =
    useFetchDataSourceRequirements();

  const { dataSources } = data;

  // Total data sources is the sum of all connected, disconnected and additionally requested
  // data sources.
  const numTotal = useMemo(
    () =>
      dataSources.length +
      (dataSourceRequirements?.filter((x) => x.type === "additional").length ?? 0),
    [dataSources, dataSourceRequirements],
  );

  const numSyncing = useMemo(
    () =>
      dataSources.map((ds) => {
        return ds.lastSyncCompletedAt != null;
      }).length,
    [dataSources],
  );
  const numDisconnected = useMemo(
    () =>
      dataSources.filter((ds) => {
        return ds.userActionRequiredAt != null;
      }).length,
    [dataSources],
  );

  const label = (() => {
    if (numTotal === 0) {
      return "0 Connections";
    }
    if (numDisconnected === 1) {
      return "1 Critical Error";
    }
    if (numDisconnected > 1) {
      return `${numDisconnected} Critical Errors`;
    }
    return `${numSyncing}/${numTotal} Connections`;
  })();

  const badgeVariant = (() => {
    if (numTotal === 0) {
      return "default";
    }
    if (numDisconnected > 0) {
      return "warning";
    }
    return "syncing";
  })();

  return (
    <ConnectionBadge
      label={label}
      onClick={onClick}
      variant={badgeVariant}
      loading={loading || requirementsLoading}
    />
  );
};
