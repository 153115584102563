import styled from "@emotion/styled";
import React from "react";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { IImageOrSVG, ImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";

type IListItemProps = {
  label: React.ReactNode;

  /**
   * An optional description; if included, turns the list option into a complex option.
   */
  description?: string | React.ReactNode;

  icon?: IImageOrSVG;
  iconNode?: React.ReactNode;

  /**
   * Adds a circular background behind the icon
   */
  hasIconCircleBackground?: boolean;

  /**
   * A react component which appears on the right-hand side of the list item
   */
  secondaryAction?: React.ReactNode;

  /**
   * Default behavior is to align the secondary action to the top-right. If true, aligns to the center right
   */
  isCenterSecondaryAction?: boolean;

  className?: string;
};

const ListBoxItemTexts: React.FC<IListItemProps> = ({ label, description }) => {
  if (description == null) {
    return (
      <TextWrapper>
        <ItemLabel>{label}</ItemLabel>
      </TextWrapper>
    );
  }

  return (
    <TextWrapper>
      <ItemTitle>{label}</ItemTitle>
      <ItemDescription>{description}</ItemDescription>
    </TextWrapper>
  );
};

export const ListBoxItem: React.FC<IListItemProps> = (props) => {
  const {
    icon,
    iconNode,
    secondaryAction,
    isCenterSecondaryAction = false,
    hasIconCircleBackground = false,
    className,
  } = props;

  const IconElement = icon != null ? <ImageOrSVG src={icon} /> : iconNode != null ? iconNode : null;

  return (
    <ListBoxWrapper className={className}>
      {IconElement != null && (
        <IconWrapper hasIconCircleBackground={hasIconCircleBackground}>{IconElement}</IconWrapper>
      )}
      <ListBoxItemTexts {...props} />
      {isCenterSecondaryAction ? <ActionWrapper>{secondaryAction}</ActionWrapper> : secondaryAction}
    </ListBoxWrapper>
  );
};

const IconWrapper = styled.div<{ hasIconCircleBackground: boolean }>`
  width: ${({ theme }) => theme.components.ListBox.icon.backgroundSize};
  height: ${({ theme }) => theme.components.ListBox.icon.backgroundSize};
  display: grid;
  place-content: center;

  background-color: ${({ hasIconCircleBackground, theme }) =>
    hasIconCircleBackground && theme.components.ListBox.icon.backgroundColor};
  border-radius: ${({ hasIconCircleBackground, theme }) =>
    hasIconCircleBackground && theme.borderRadii.circle};
`;

const ListBoxWrapper = styled(Flexbox)`
  align-items: center;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing.xs};
`;

const TextWrapper = styled(Flexbox)`
  flex-grow: 1;
  justify-content: center;
`;

const ActionWrapper = styled(Flexbox)`
  justify-content: center;
`;

const ItemLabel = styled.div`
  color: ${(props) => props.theme.components.ListBox.itemLabelTextColor};
  font-size: ${(props) => props.theme.components.ListBox.font.size};
  font-weight: ${(props) => props.theme.components.ListBox.font.weight};
  line-height: ${(props) => props.theme.components.ListBox.font.lineHeight};
`;

const ItemTitle = styled.div`
  color: ${(props) => props.theme.components.ListBox.itemLabelTextColor};
  font-size: ${(props) => props.theme.components.ListBox.font.size};
  font-weight: ${(props) => props.theme.components.ListBox.font.weight};
  line-height: ${(props) => props.theme.components.ListBox.font.lineHeight};
`;

const ItemDescription = styled.div`
  color: ${(props) => props.theme.components.ListBox.itemDescriptionTextColor};
  font-size: ${(props) => props.theme.components.ListBox.font.secondarySize};
  font-weight: ${(props) => props.theme.components.ListBox.font.weight};
  line-height: ${(props) => props.theme.components.ListBox.font.lineHeight};
`;
