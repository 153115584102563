/* eslint-disable react/style-prop-object */
import React from "react";

export const CA: React.FC = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="85.35 0 341.3 341.3">
    <rect y="0" fill="#FFFFFF" width="512" height="341.3" />
    <g>
      <rect y="0" fill="#D80027" width="142" height="341.3" />
      <rect x="370" y="0" fill="#D80027" width="142" height="341.3" />
      <polygon
        fill="#D80027"
        points="306.5,206 356.9,180.8 331.7,168.2 331.7,143 281.3,168.2 306.5,117.8 281.3,117.8 256.1,80
		230.9,117.8 205.7,117.8 230.9,168.2 180.5,143 180.5,168.2 155.3,180.8 205.7,206 193.1,231.2 243.5,231.2 243.5,269 268.7,269
		268.7,231.2 319.1,231.2 	"
      />
    </g>
  </svg>
);
