/** @jsxImportSource @emotion/react */
import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { FaFileExcel } from "react-icons/fa";
import stringify from "safe-stable-stringify";

import { adminRequests } from "~src/admin/requests";
import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import { logError } from "~src/shared/helpers/loggers";
import { clampPercent } from "~src/shared/helpers/math";
import { callRequest } from "~src/shared/requests/useRequest";
import { vendorRequests } from "~src/vendor/requests";

import { IconText } from "../../molecules/IconText";
import { Button } from "../Button";
import { ProgressBar } from "../ProgressBar";
import { Dropzone } from ".";
import { IFile, useFilesUploading } from "./useFilesUploading";

interface IProps {
  /**
   * Directory in which file will be stored, e.g. "billing_manager" or "accounting".
   */
  directory: string;
  /**
   * List of MIME file types to accept (uses MIME types)
   */
  accepts?: readonly string[];
  /**
   * List of file extensions to accept -- merged with accepts
   */
  extensions?: readonly string[];
  /**
   * The maximum number of files that can be uploaded. Null is unlimited
   */
  maxNumOfFiles?: number;
  /**
   * Whether the user is able to remove files
   */
  canRemoveFiles?: boolean;
  /**
   * The file owner's vendor public ID. Setting it as null routes the upload to the Vendor request, else, it uses the Admin request.
   */
  vendorPublicID?: string;
  onFilesUpdate: (files: readonly IFile[]) => void;
}

const makeCommaList = (extensions: readonly string[]) => {
  const ups = extensions.map((x) => x.toUpperCase());
  if (ups.length === 1) {
    return ups[0];
  }
  return `${ups.slice(0, -1).join(", ")} or ${ups[ups.length - 1]}`;
};

export const VendorDropzone: React.FC<IProps> = ({
  directory,
  accepts,
  extensions,
  maxNumOfFiles,
  vendorPublicID,
  canRemoveFiles = false,
  onFilesUpdate,
}) => {
  const { setPercent, onSuccess, onError, removeFile, files: filesRaw } = useFilesUploading();
  const files = filesRaw.filter((file) => file.state !== "error");

  const filesKey = useMemo(() => stringify(files), [files]);

  useEffect(() => {
    onFilesUpdate(files);
    // NOTE(johnrjj) - Override dep array check with hashed key set, more reliable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesKey]);

  const acceptsStr = [
    ...(accepts ?? []),
    ...(extensions?.map((x) => `.${x.toLowerCase()}`) ?? []),
  ].join(",");

  return (
    <Dropzone
      hideFileList
      hideDropzone={files.length > 0}
      dropzoneOptions={{
        accept: acceptsStr,
        onDrop: async (onDropFiles: File[]) => {
          const file = onDropFiles[0];
          if (file == null) {
            return;
          }

          const fileUploadUrlParam = {
            fileName: `${directory}/${file.name}`,
            contentType: file.type,
          };

          const { data } = await callRequest(
            vendorPublicID == null
              ? vendorRequests.getFileUploadUrl(fileUploadUrlParam)
              : adminRequests.getFileUploadUrl({
                  ...fileUploadUrlParam,
                  vendorPublicID,
                }),
          );
          const url = data?.uploadURL;
          const fileName = data?.fileName;
          if (url == null || fileName == null) {
            onError(file);
            return;
          }

          try {
            await axios.put(url, file, {
              withCredentials: false,
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress: ({ total, loaded }) => {
                setPercent(file, clampPercent(loaded / total), fileName);
              },
            });

            onSuccess(file, url, fileName);
          } catch (e) {
            logError(e, {
              extra: {
                fileName,
                url,
              },
            });
            toast.error("Something went wrong with the upload. Our engineers have been notified.", {
              duration: 8,
            });
            onError(file, url, fileName);
          }
        },
      }}
      placeholder={
        <Info>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 0C9.55178 0 6.24631 1.36944 3.80693 3.80693C1.36944 6.24606 0 9.55166 0 13C0 16.4483 1.36944 19.7537 3.80693 22.1931C6.24606 24.6306 9.55166 26 13 26C16.4483 26 19.7537 24.6306 22.1931 22.1931C24.6306 19.7539 26 16.4483 26 13C26 9.55166 24.6306 6.24631 22.1931 3.80693C19.7539 1.36944 16.4483 0 13 0V0ZM14.2581 23.4037V11.0034L18.0322 14.7776L19.8096 13.0003L13.8896 7.07856C13.3981 6.58876 12.602 6.58876 12.1106 7.07856L6.19058 13.0003L7.96793 14.7776L11.7421 11.0034V23.4037C8.1727 22.9729 5.07355 20.7418 3.53033 17.4933C1.98723 14.2466 2.21658 10.4331 4.13805 7.39446C6.05953 4.35578 9.40471 2.51277 13.0003 2.51277C16.5958 2.51277 19.941 4.35561 21.8624 7.39446C23.7839 10.4331 24.0133 14.2467 22.4702 17.4933C20.9271 20.7417 17.8279 22.9726 14.2584 23.4037H14.2581Z"
              fill="#81808D"
            />
          </svg>
          <Desc>
            <span
              css={(theme: Theme) => css`
                font-size: 15px;
                font-weight: 500;
                color: ${theme.components.Dropzone.text.default};
                line-height: 22px;
              `}
            >
              Click or drag here to upload
            </span>
            {extensions && (
              <span
                css={(theme: Theme) => css`
                  font-size: 12px;
                  font-weight: 400;
                  color: ${theme.components.Dropzone.text.default};
                  line-height: 22px;
                `}
              >
                {makeCommaList(extensions)} format
              </span>
            )}
          </Desc>
        </Info>
      }
    >
      {({ dropzone }) => (
        <>
          {files.length > 0 && (
            <FileList>
              {files.map((file, i) =>
                file.state === "success" ? (
                  <a
                    key={i}
                    href="#"
                    onClick={async () => {
                      const { url } = file;
                      if (url == null) {
                        toast.error("Invalid file.");
                        return;
                      }

                      try {
                        const urlWithoutPrefix = new URL(url);
                        const fileName = urlWithoutPrefix.pathname.split("/").slice(2).join("/");
                        const getFileDownloadURL =
                          vendorPublicID != null
                            ? adminRequests.getFileDownloadURL
                            : vendorRequests.getFileDownloadURL;
                        const { data } = await callRequest(
                          getFileDownloadURL({
                            fileName: decodeURIComponent(fileName),
                          }),
                        );
                        if (data === null) {
                          throw new Error("File upload failed.");
                        }
                        window.open(data.downloadURL, "_blank");
                      } catch (e) {
                        toast.error(e.message);
                      }
                    }}
                  >
                    <FileRow>
                      <FaFileExcel />
                      <FileName>{file.file.name}</FileName>
                      {canRemoveFiles && (
                        <Button
                          kind="secondary"
                          size="medium"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFile(file.file);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </FileRow>
                  </a>
                ) : file.state === "error" ? (
                  <FileRow key={i}>
                    <FaFileExcel />
                    <FileName>{file.file.name}</FileName>
                  </FileRow>
                ) : (
                  <ProgressBar key={i} value={file.percent} />
                ),
              )}
              {!(maxNumOfFiles != null && files.length >= maxNumOfFiles) && (
                <a onClick={dropzone.open}>
                  <IconText iconName="plus" iconSize={12} iconColor="#fff">
                    Add another
                  </IconText>
                </a>
              )}
            </FileList>
          )}
        </>
      )}
    </Dropzone>
  );
};

const FileList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
`;

const FileRow = styled.div`
  display: grid;
  grid-template-columns: 32px minmax(0, 1fr) min-content;
  width: 100%;
  grid-column-gap: 10px;
  align-items: center;

  svg {
    height: 32px;
    width: 32px;
  }
`;

const Info = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 26px 1fr;
  grid-column-gap: 15px;

  margin: auto;
  svg {
    height: 26px;
    width: 26px;
  }
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1em;
`;

const FileName = styled(OldSpanUseTextInstead)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
