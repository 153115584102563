import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { permission_enum_enum } from "~src/__generated__/graphql/types";
import { useUser } from "~src/shared/auth/useUser";
import {
  IFeatureFlagChecker,
  IFeatureFlagName,
  useFeatureFlagChecker,
} from "~src/shared/featureFlags";
import { IGetUserProfileData } from "~src/shared/requests/authedRequests";

export type ISubnavLink = {
  title: string;
  href: string;
  permission?: permission_enum_enum;
  // Show subnav item if this feature flag evaluates to true for the user
  featureFlagShow?: IFeatureFlagName;
  // Hide subnav item if this feature flag evaluates to true for the user
  featureFlagHide?: IFeatureFlagName;
  highlightPathIncludes?: string;
};

type ISubnavSection = {
  title?: string;
  links: readonly ISubnavLink[];
};

export type ISubnav = {
  sections: readonly ISubnavSection[];
  endContent?: React.ReactElement;
};

export const Subnav = (props: ISubnav): React.ReactElement => {
  const { sections, endContent } = props;
  const router = useRouter();
  const user = useUser();
  const featureFlagChecker = useFeatureFlagChecker();

  const accessibleSections = filterAccessibleSections(sections, user, featureFlagChecker);

  return (
    <Wrapper>
      {accessibleSections.map((section, idx) => (
        <ItemGroup key={`${section.title}-${idx}`}>
          <h2>{section.title}</h2>
          <Links>
            {section.links.map((link) => {
              const isSelected =
                link.highlightPathIncludes !== undefined
                  ? router.asPath.includes(link.href)
                  : router.asPath.endsWith(link.href);
              return (
                <Link key={link.href} href={link.href} passHref>
                  <SubnavLink selected={isSelected}>
                    {isSelected && <SidebarActiveMarker />}
                    <span>{link.title}</span>
                  </SubnavLink>
                </Link>
              );
            })}
          </Links>
        </ItemGroup>
      ))}
      {endContent}
    </Wrapper>
  );
};

// useFilterAccessibleSections removes the links that the user cannot access.
// If a section does not have any links left, then we hide the section as well.
//
// Because this function uses feature flags, it is a hook.
//
// A link can be rendered partially accessible if:
//
// - A permission is assigned to it. Then the link is only accessible if the
//   user has the permission.
// - A feature flag is assigned to it. Then the link is only accessible if the
//   user has the feature flag.
//
const filterAccessibleSections = (
  sections: readonly ISubnavSection[],
  user: IGetUserProfileData,
  featureFlagChecker: IFeatureFlagChecker,
): ISubnavSection[] => {
  const accessible: ISubnavSection[] = [];

  sections.forEach((section) => {
    const links = section.links.filter(
      ({ title, permission, featureFlagShow, featureFlagHide }) =>
        (permission === undefined || user.permissions.includes(permission)) &&
        (featureFlagShow === undefined || featureFlagChecker(featureFlagShow)) &&
        (featureFlagHide === undefined || !featureFlagChecker(featureFlagHide)) &&
        (title !== "Legal Entity" || user.vendor.country === "US"),
    );

    // If the section is link-less, skip adding it!
    if (links.length !== 0) {
      accessible.push({ ...section, links });
    }
  });

  return accessible;
};

const Wrapper = styled.div`
  --left-spacing: 28px;

  padding: 20px 0;

  display: flex;
  flex-direction: column;
  gap: 20px;
  border-right: 1px solid ${({ theme }) => theme.components.Divider.default};
  overflow-y: auto;
  position: relative;
`;

const ItemGroup = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 16px;
  align-content: flex-start;
  & > h2 {
    margin-left: var(--left-spacing);
    ${{
      fontSize: "11px",
      fontWeight: 600,
      // textTransform: "uppercase",
      lineHeight: "16px",
    }};
    text-transform: uppercase;
    color: ${({ theme }) => theme.components.Sidebar.SectionHeader.text};
  }
`;

const Links = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 4px;
`;

const SubnavLink = styled.a<{ selected: boolean }>`
  transition: background-color 0.1s ease;
  transition: color 0.1s ease;
  display: grid;
  grid-template-columns: none;
  // NOTE: If/when we ever add the icons back, we can uncomment
  // the line below
  // grid-template-columns: 20px 1fr;
  grid-column-gap: 8px;
  align-items: center;
  padding-left: var(--left-spacing);
  height: 32px;
  ${{
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "16px",
  }};
  color: ${({ theme }) => theme.components.Sidebar.SubnavLink.text.inactive};

  &:hover {
    color: ${({ theme }) => theme.components.Sidebar.SubnavLink.text.active};
    background: ${({ theme }) => theme.components.Sidebar.SubnavLink.background.hover};
  }

  ${(props) =>
    props.selected &&
    css`
      &,
      &:hover {
        color: ${props.theme.components.Sidebar.SubnavLink.text.active};
        background: ${props.theme.components.Sidebar.SubnavLink.background.active};
      }
    `};

  ${({ selected }) =>
    selected &&
    css`
      padding-left: 0;
      grid-template-columns: calc(var(--left-spacing) - 8px) 1fr;
    `}
`;

const SidebarActiveMarker = styled.div`
  width: 4px;
  height: 100%;
  background: linear-gradient(
    180deg,
    #b265ed 4.24%,
    #bc6cd4 12.72%,
    #c975b4 33.34%,
    #e28676 66.29%,
    #f1914f 100%
  );
`;
