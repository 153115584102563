import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion, MotionProps } from "framer-motion";
import { CSSProperties, ReactNode } from "react";

import {
  IBoxStyles,
  IGridStyles,
  withBoxStyles,
  withGridStyles,
} from "~src/shared/helpers/sharedComponentProps";
import { IPipeTheme } from "~src/shared/theme/darkVariant";

export type ISpanProps = {
  /** Defaults to 20px. */
  size?: "36px" | "28px" | "24px" | "20px" | "14px" | "13px" | "11px" | "10px";
  lineHeight?: 1 | 1.2 | 1.8 | 2.4;
  textAlign?: CSSProperties["textAlign"];
  weight?: 500 | 600 | 700;
  inherit?: boolean;
  color?: keyof IPipeTheme["components"]["Text"];
  bold?: boolean;
  children?: ReactNode;
  onClick?: HTMLSpanElement["onclick"];
  style?: React.CSSProperties;
} & IGridStyles &
  IBoxStyles &
  MotionProps;

/**
 * @deprecated Use Text instead.
 */
export const OldSpanUseTextInstead: React.FC<ISpanProps> = styled(motion.span)<ISpanProps>`
  font-size: ${(props) => props.size};
  font-weight: normal;
  font-weight: ${(props) => props.weight};
  font-weight: ${(props) => props.bold === true && "bold"};
  margin: 0;
  line-height: ${(props) => props.lineHeight ?? 1.8};
  text-align: ${(props) => props.textAlign};

  color: ${(props) => props.theme.components.Text.subtitle};
  color: ${(props) => props.inherit === true && "inherit"};
  color: ${(props) => props.color !== undefined && props.theme.components.Text[props.color]};

  ${(props) =>
    props.onClick &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `}

  ${(props) => withGridStyles(props)}
  ${(props) => withBoxStyles(props)}
`;
