import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { BASE_URL } from "~src/shared/env";

export const configureCodatLinkURL = (
  publicID: string,
  linkURL: string,
  redirectPath: IAllowedRedirectPath,
): string => {
  const redirectURL = new URL(BASE_URL + "/api/codat-callback");
  redirectURL.searchParams.set("redirect_path", redirectPath);
  redirectURL.searchParams.set("public_id", publicID);
  const linkURLWithRedirectURI = new URL(linkURL);
  linkURLWithRedirectURI.searchParams.set("redirect_uri", redirectURL.href);
  return linkURLWithRedirectURI.href;
};
