import styled from "@emotion/styled";
import { useProgressBar } from "@react-aria/progress";
import { AriaProgressBarProps } from "@react-types/progress";

import { Flexbox } from "~src/designSystem/layout/Flexbox";

export type IProgressBarProps = AriaProgressBarProps & {
  /**
   * Height of the bar.
   *
   * @default 6
   */
  height?: number;
};

export const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  const {
    label,
    showValueLabel = label != null,
    value = 0,
    minValue = 0,
    maxValue = 100,
    height = 6,
  } = props;
  const { progressBarProps, labelProps } = useProgressBar(props);

  // Calculate the width of the progress bar as a percentage
  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;

  return (
    <Wrapper {...progressBarProps}>
      <Flexbox row justifyContent="space-between">
        {label != null && <span {...labelProps}>{label}</span>}
        {showValueLabel && <span>{progressBarProps["aria-valuetext"]}</span>}
      </Flexbox>
      <Background height={height}>
        <Foreground height={height} width={barWidth} />
      </Background>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Background = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.theme.components.ProgressBar.bar};
  border-radius: 100px;
`;

const Foreground = styled.div<{ height: number; width: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.theme.components.ProgressBar.progress};
  border-radius: 100px;
`;
