import { logErrorEventAndToast } from "~src/shared/helpers";

import { IPlaidError, IPlaidOnExitMetadata } from "./types";

export const onErrorCommon = (onExitErr: IPlaidError, metadata: IPlaidOnExitMetadata): void => {
  if (onExitErr.error_type === "ITEM_ERROR") {
    // Ignore user errors such as invalid credentials.
    return;
  }
  switch (onExitErr.error_code) {
    case "RATE_LIMIT":
      // Ignore reCAPTCHA errors.
      return;
    case "institution-poor-health-error":
      // Ignore temporary institution connectivity errors. Plaid displays a warning to the user
      // before they input their credentials.
      return;
    case "INTERNAL_SERVER_ERROR":
      // Plaid is experiencing internal system issues. Plaid will prompt the user to retry.
      return;
    case "INSTITUTION_NOT_RESPONDING":
      // The institution is experiencing temporary technical issues. Plaid will prompt the user
      // to retry.
      return;
    default:
      // Report all other errors to Sentry.
      logErrorEventAndToast({
        message: `Plaid ${onExitErr.error_code}`,
        extra: {
          error: onExitErr,
          linkSessionID: metadata.link_session_id,
          institutionID: metadata.institution?.institution_id,
          institutionName: metadata.institution?.name,
          metadata,
        },
        tags: {
          category: "plaid",
        },
      });
  }
};
