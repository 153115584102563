import React, { useState } from "react";

import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { DataSourceLayout } from "~src/shared/dataSources/components/dataSourceLayout";
import { BASE_URL } from "~src/shared/env";
import { useEnv } from "~src/shared/env/useEnv";
import { useStepper } from "~src/shared/stepper/stepperContext";

import { BILLING_MANAGERS } from "../billingManagers";

interface IProps {
  redirectPath: IAllowedRedirectPath;
}

export const ConnectGoCardless: React.FC<IProps> = ({ redirectPath }) => {
  const env = useEnv();
  const { removeTopOfStack } = useStepper();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const redirectToGoCardless = () => {
    setIsRedirecting(true);
    const params = new URLSearchParams({
      scope: "read_only",
      redirect_uri: BASE_URL + `/api/gocardless-oauth-callback?redirect_path=${redirectPath}`,
      response_type: "code",
      client_id: env.GOCARDLESS_CLIENT_ID,
    });

    const goCardlessBaseURL =
      env.GOCARDLESS_ENV === "sandbox"
        ? "https://connect-sandbox.gocardless.com"
        : "https://connect.gocardless.com";

    // Redirect to GoCardless
    window.location.href = `${goCardlessBaseURL}/oauth/authorize?${params.toString()}`;
  };

  return (
    <DataSourceLayout
      dataSourceInfo={BILLING_MANAGERS.gocardless}
      heading="Authenticate with OAuth"
      description="Securely connect your GoCardless account via OAuth. You’ll be directed to GoCardless' website to sign in. Once you’re done, you’ll be redirected back to Pipe."
      secondaryButtonText="Back"
      secondaryButtonAction={removeTopOfStack}
      primaryButtonText={
        isRedirecting ? "Transferring you to GoCardless..." : "Continue to GoCardless"
      }
      primaryButtonAction={redirectToGoCardless}
      isPrimaryButtonDisabled={isRedirecting}
    />
  );
};
