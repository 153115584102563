import styled from "@emotion/styled";

import { LoadingSpinner } from "~src/designSystem/loading/LoadingSpinner";

export const ConnectDataSourceSearching: React.FC = () => (
  <Wrapper>
    <LoadingSpinner size="50px" ringWidth="4px" />
  </Wrapper>
);

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xxl};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.lg};
`;
