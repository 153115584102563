/* eslint-disable react/style-prop-object */
import React from "react";

export const Chargebee: React.FC = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-244 134.7 311 333.3"
    xmlSpace="preserve"
  >
    <g id="guides_1_" />
    <g id="Layer_4">
      <g>
        <path
          fill="#FF6C36"
          d="M-7.8,343.2c-1.9-1.3-4.4-1.2-6.3,0.1c-1.9,1.3-2.7,3.8-2,6s2.7,3.8,5,3.9c2.3,0.1,4.4-1.2,5.3-3.3
			C-4.8,347.5-5.6,344.6-7.8,343.2C-10.2,341.5-5.3,344.9-7.8,343.2z"
        />
        <path
          fill="#FF6C36"
          d="M-77.6,394.1c1.2,0,2.5,0,3.7-0.1c2.5-0.1,5-0.3,7.4-0.6c2-0.2-4.1,0.5,0,0c2.5-0.3,4.5,2,4,4.5
			c-0.3,1.1-1.1,2-2.1,2.5c-0.7,0.3-1.4,0.3-2.1,0.4c-0.8,0.1-1.7,0.2-2.5,0.2c-6.6,0.5-13.2,0.4-19.8-0.3c-6-0.7-11.8-1.8-17.6-3.6
			c-5.6-1.7-11-3.9-16.2-6.6c-5.3-2.7-10.2-5.9-14.9-9.5c-5.4-4-10.3-8.6-14.7-13.7c-4.4-5-8.3-10.5-11.6-16.2
			c-3.4-5.9-6.2-12.1-8.3-18.6c-2.2-6.6-3.6-13.4-4.4-20.3c-0.8-7-0.8-14.1-0.1-21.1c0.7-6.9,2.1-13.8,4.3-20.4
			c2.1-6.5,4.8-12.7,8.2-18.7c3.3-5.9,7.3-11.4,11.7-16.5c4.4-5,9.3-9.6,14.6-13.7c5.4-4.1,11.2-7.7,17.2-10.6
			c6.2-3,12.7-5.3,19.4-7c6.8-1.7,13.8-2.6,20.8-2.9c7-0.2,14.1,0.3,21,1.5c6.7,1.2,13.3,3.1,19.7,5.6c6.3,2.5,12.3,5.7,17.9,9.4
			c5.6,3.7,10.8,8,15.6,12.8c2,2,3.8,4,5.1,6.6c1.1,2.2,1.7,4.7,1.9,7.1c0.3,5-1.4,10.1-4.8,13.8c-3.9,4.4-9.8,6.8-15.6,6.4
			c-2.8-0.2-5.6-1-8.1-2.4c-1.3-0.7-2.5-1.6-3.5-2.6c-1.2-1.1-2.3-2.4-3.5-3.5c-4.9-4.6-10.5-8.3-16.6-11.1
			c-6.3-2.8-13-4.6-19.8-5.2c-7-0.6-14.1-0.1-20.9,1.6c-6.6,1.7-12.9,4.5-18.7,8.2c-5.6,3.7-10.6,8.3-14.7,13.5
			c-4.2,5.3-7.5,11.3-9.8,17.7c-2.3,6.5-3.5,13.4-3.5,20.3c0,7,1.1,13.9,3.4,20.5c2.2,6.4,5.5,12.3,9.6,17.6
			c4.1,5.3,9.1,9.9,14.7,13.6c5.7,3.8,12,6.6,18.7,8.3c6.8,1.8,13.9,2.3,20.9,1.7c6.8-0.6,13.5-2.3,19.8-5.1c0.7-0.3,1.3-0.7,2-0.8
			c0.6-0.1,1.2-0.1,1.8,0.1c1.2,0.4,2.1,1.3,2.4,2.5c0.3,1.1,0.1,2.4-0.7,3.3c-0.4,0.5-1,0.8-1.6,1.1c-0.8,0.3-1.5,0.7-2.3,1
			c-6.4,2.7-13.1,4.4-20,5c-7,0.7-14.1,0.3-20.9-1.1c0.9,1,1.7,2.1,2.5,3.2c0.8,1.1,1.6,2.3,2.3,3.4c0.1,0.2,0.4,0.6,0.4,0.6
			s0.1,0,0.3,0c0.4,0,0.7,0.1,1.1,0.1c0.7,0.1,1.4,0.1,2.1,0.2c2.9,0.2,5.8,0.2,8.7,0.1c5.7-0.3,11.4-1.1,16.9-2.7
			c5.3-1.5,10.5-3.6,15.4-6.2c4.8-2.6,9.3-5.6,13.4-9.2c1-0.9,2-1.8,3-2.7c0.9-0.8,1.6-1.8,2.7-2.3c1.9-1,4.2-0.7,5.8,0.6
			c1.7,1.3,2.4,3.5,1.9,5.6c-0.3,1.1-0.8,2-1.6,2.7c-1,0.9-1.9,1.9-2.9,2.8c-4,3.6-8.3,6.9-12.9,9.7c-4.7,2.9-9.6,5.3-14.8,7.2
			c-5.3,2-10.7,3.4-16.3,4.3c-5.7,0.9-11.5,1.3-17.3,1.1c0.5,1.3,0.9,2.6,1.3,3.9c0.2,0.6,0.3,1.2,0.5,1.8c0,0.2,0.2,0.7,0.2,0.7
			L-77.6,394.1z"
        />
        <path
          fill="#FF6C36"
          d="M-21.8,375.7c-5.6,4.2-11.7,7.8-18.1,10.6c-3.2,1.4-6.5,2.6-9.9,3.7c-1.7,0.5-3.4,1-5.1,1.4
			c-0.7,0.2-1.5,0.3-2.2,0.7c-0.6,0.3-1,0.8-1.3,1.4c-0.7,1.3-0.5,2.8,0.4,3.9c1,1.3,2.5,1.5,4,1.2c3.4-0.8,6.8-1.8,10.1-3
			c6.6-2.3,13-5.4,19-9.1c1.5-0.9,3-1.9,4.4-2.9c0.7-0.5,1.5-1,2.2-1.5c0.6-0.5,1.3-0.9,1.8-1.6c0.8-1.1,0.8-2.7,0.1-3.9
			s-2.1-1.9-3.4-1.8C-20.6,375-21.3,375.3-21.8,375.7C-32.1,383.3-20.2,374.5-21.8,375.7z"
        />
        <path
          fill="#FF6C36"
          d="M-9.8,364.9c-0.9,1-1.9,2-2.9,3c-0.8,0.8-1.7,1.5-2.1,2.6c-0.6,2.1,0.8,4.3,2.9,4.6
			c1.3,0.2,2.4-0.3,3.3-1.1c1-0.9,2-1.9,2.9-2.9c0.8-0.8,1.7-1.6,2-2.8c0.2-0.9,0.1-2-0.4-2.8C-5.3,363.5-8.2,363.2-9.8,364.9
			C-11.1,366.3-8.4,363.4-9.8,364.9z"
        />
        <path
          fill="#FF6C36"
          d="M-32.5,343.9c-2.3,2.4-4.7,4.6-7.3,6.6c-0.7,0.5-1.5,1.1-2.2,1.6c-0.6,0.4-1.1,1-1.4,1.7
			c-0.6,1.4-0.2,3.1,1,4.1c1.4,1.2,3.2,1.1,4.7,0c1.4-1,2.8-2,4.1-3c1.2-1,2.4-2.1,3.6-3.2c0.7-0.6,1.3-1.3,2-2
			c0.6-0.6,1.2-1.2,1.6-2c0.6-1.6,0-3.5-1.5-4.4C-29.4,342.5-31.3,342.7-32.5,343.9C-36,347.6-31.1,342.5-32.5,343.9z"
        />
        <path
          fill="#FF6C36"
          d="M-77.2,277.7c-4.9,0-9.7,1.5-13.7,4.4c-3.9,2.8-6.9,6.7-8.5,11.2c-1.7,4.6-1.9,9.8-0.5,14.6
			c1.3,4.6,4,8.7,7.7,11.7c3.7,3.1,8.4,5,13.2,5.3c4.9,0.4,9.9-0.8,14.1-3.4c4.1-2.5,7.3-6.1,9.3-10.5c2-4.5,2.6-9.6,1.6-14.5
			c-0.9-4.7-3.4-9.1-6.8-12.4c-3.5-3.3-8-5.5-12.7-6.3C-74.8,277.8-76,277.7-77.2,277.7z"
        />
      </g>
    </g>
  </svg>
);
