import { asset_rating_enum_enum, permission_enum_enum } from "~src/__generated__/graphql/types";
import {
  IDemoContractTypes,
  IDemoCurrency,
  IDemoNumStreams,
  IDemoRating,
  IDemoTermLength,
} from "~src/admin/demos/types";
import { INetworkRequest } from "~src/shared/requests/types";
import {
  INote,
  ISearchQuery,
  ISearchResult,
  IUploadedVendorDataSourceModel,
} from "~src/shared/types";
import { IUpdateVendorArgs, IUpsertLegalEntityArgs } from "~src/vendor/requests";

type Empty = Record<string, never>;

export type IVendorDemoArgs = {
  addressableARR: number | undefined;
  tradeLimit: number | undefined;
  rating: IDemoRating;
  currency: IDemoCurrency;
  numStreams: IDemoNumStreams;
  contractTypes: IDemoContractTypes;
  termLength: IDemoTermLength;
  treasuryAgeDays: number | undefined;
};

export interface ICreateSnapshotArgs {
  vendorMetricsPublicID: string;
  vendorPublicID: string;
  accountingPeriodEnd: string;
  historicalBankDataPublicIDs: string[];
  overrides: ISnapshotOverrides;
  dataValidated: boolean;
  note: INote;
  suggestedDecision?: ISuggestedDecision;
  appliedDecision?: Omit<ISuggestedDecision, "commitSHA" | "scoreExplanation">;
}

export interface IUpdateVendorRequirementsArgs {
  vendorPublicID: string;
  notifyVendor: boolean;
  isOnHold?: boolean;
  canTrade?: boolean;
  /** Internal note for admins only */
  note?: INote;
  /** Message to send to vendor users */
  notifyVendorCustomMessage?: INote;
  requirements: IVendorRequirements;
}

interface IVendorRequirements {
  isAdditionalBillingManagerRequired?: boolean | null;
  isAdditionalBankLinkRequired?: boolean | null;
  isAdditionalAccountingLinkRequired?: boolean | null;
}

export interface ISnapshotOverrides {
  // Vendor metrics overrides.
  total_arr?: number;
  total_adressable_arr?: number;
  revenue_ratio_qoq?: number;
  customer_ratio_qoq?: number;

  // Historical bank data overrides.
  balance?: number;
  burn?: number;
  adjusted_burn?: number;

  // Accounting metrics overrides.
  revenue?: number;
  cogs?: number;
  gross_margin?: number;
  total_expenses?: number;
  cash_balance?: number;
  net_income_trend_qoq?: number;
  current_assets?: number;
  total_assets?: number;
  current_liabilities?: number;
  total_liabilities?: number;

  // retention metrics
  revenueRetention1MAvg?: number;
  revenueRetention6MAvg?: number;
  revenueRetention12MAvg?: number;
  customerRetention1MAvg?: number;
  customerRetention6MAvg?: number;
  customerRetention12MAvg?: number;
}

export interface IVendorFile {
  bucket: string;
  name: string;
  contentType: string;
  contentLanguage: string;
  size: string;
  contentEncoding: string;
  contentDisposition: string;
  created: string;
  metadata: Record<string, string>;
}

export interface IVendorRetentionResponseQuarter {
  quarter: string;
  amount: number;
  ratio: number;
}

export interface IVendorRetentionResponse {
  revenueOK: boolean;
  subscriptionsOK: boolean;
  revenue: IVendorRetentionResponseQuarter[];
  subscriptions: IVendorRetentionResponseQuarter[];
}

export interface IVendorWaterfallItem {
  CohortMonth: string;
  Month: string;
  Revenue: number;
  Subscriptions: number;
}

export type IVendorWaterfallResponse = IVendorWaterfallItem[];

type ISuggestedDecisionArgs = {
  vendorMetricsPublicID: string;
  accountingPeriodEnd: string;
  historicalBankDataPublicIDs: string[];
  overrides: ISnapshotOverrides;
};

export type ISuggestedDecision = {
  decision: "approved" | "declined";
  creditLimit: number;
  creditLimitExpirationDate: string;
  rateMonths1: number;
  maxTerm: number;
  score: number;
  rating: string;
  commitSHA: string;
  scoreExplanation?: ISuggestedDecisionScoreExplanation;
};

export interface ISuggestedDecisionScoreExplanation {
  financial: ISuggestedDecisionFinancialScores;
  portfolio: ISuggestedDecisionPortfolioScores;
  stressTest: ISuggestedDecisionStressTestScores;
}

export type ISuggestedDecisionFinancialScoreComponents =
  | "adjustedRunway"
  | "cashBalance"
  | "debtRatio"
  | "grossMargin"
  | "netIncomeTrendQoq"
  | "operatingRatio"
  | "runway";

export type ISuggestedDecisionFinancialScores = Record<
  ISuggestedDecisionFinancialScoreComponents,
  ISuggestedDecisionWeightedScore
>;

export type ISuggestedDecisionPortfolioScoreComponents =
  | "arr"
  | "cohortRetention12m"
  | "cohortRetention1m"
  | "cohortRetention6m"
  | "customerRatioQoQ"
  | "delinquencyRatio"
  | "revenueRatioQoQ";

export type ISuggestedDecisionPortfolioScores = Record<
  ISuggestedDecisionPortfolioScoreComponents,
  ISuggestedDecisionWeightedScore
>;

export type ISuggestedDecisionStressTestScoreComponents =
  | "stressTest0"
  | "stressTest1"
  | "stressTest2";

export type ISuggestedDecisionStressTestScores = Record<
  ISuggestedDecisionStressTestScoreComponents,
  ISuggestedDecisionWeightedScore
>;
interface ISuggestedDecisionWeightedScore {
  score: number;
  weight: number;
}

export type IManualOperation = {
  name: string;
  description: string;
  tags: string[];
  permissions: permission_enum_enum[] | null;
  schema: Record<string, IManualOperationSchemaType>;
};
export type IManualOperationSchemaType = "boolean" | "number" | "date" | "string" | "string[]";

export type IFillSellOrderRequestArgs = {
  sellOrderID: string;
  fillLimit?: string;
  fillStrategy: "limit_buy_order" | "backstop" | "balance_sheet";
  limitBuyOrderID?: string;
};

export type ILimitBuyOrder = {
  publicID: string;
  investor: {
    publicID: string;
    name: string;
    clientID: string;
  };
  currency: string;
  amountLimit: number;
  amountUnfilled: number;
  assetRating: string;
  minTermMonths: number | null;
  maxTermMonths: number | null;
  irrBPS: number;
  status: string;
  expiresAt: string | null;
};

export type IProfile = {
  email: string;
  status_text: string;
  image_24: string;
  image_512: string;
};

export type ITeamMember = {
  id: string;
  name: string;
  real_name: string;
  tz: string;
  tz_label: string;
  profile: IProfile;
  // only set when querying for a single User
  // because it requires calling a separate endpoint
  presence?: string;
  // TODO: populate in backend
  lat: number;
  lng: number;
};

export type IRatingInputData = {
  DailyRecurringContractCount: number;
  DailyRecurringContractValue: number;
  DailyChurnedContractCount: number;
  DailyChurnedContractValue: number;
  DailyActiveUserCountBillingManager: number;
  DailyDelinquentContractValue: number;
  CohortRetention1m: number;
  CohortRetention6m: number;
  CohortRetention12m: number;
  DailyInflows: number;
  DailyEquityInflows: number;
  DailyDebtInflows: number;
  DebtInterestAndPrinciplePayments: number;
  DailyOutflows: number;
  DailyRevenueBank: number;
  DailyExpenses: number;
  DailyCloudExpense: number;
  DailySalesAndMarketingSpend: number;
  DailyLogisticsAndShippingCosts: number;
  TotalCashInTheBank: number;
  TotalAddressableARR: number;
};

export type IPrediction = {
  rating: string;
  discount_rate_1m: number;
  max_tradeable_term_length: number;
  utilization_limits_pct: Map<string, number>;
  vendor_bid_prices: Map<string, number>;
  debug_info: string;
  explanations: Map<string, string>;
};

export type IRatingOutput = {
  model_version_id: string;
  predictions: Array<IPrediction>;
};

export type IRatingDebug = {
  dates: Array<string>;
  inputs: Array<IRatingInputData>;
  input_error: string;
  output: IRatingOutput;
  output_error: string;
};

const makeAdminRequest =
  <I, O>(action: string) =>
  (input: I): INetworkRequest<Readonly<I>, Readonly<O>> => ({
    action,
    type: "admin",
    input,
  });

export const adminRequests = {
  // Low end experiment
  setLowEndExperimentCandidateTiers:
    makeAdminRequest<{ vendorID: string; tiers: string[] }, Empty>("low_end_candidates.set"),

  // Permissions
  addRoleToUser:
    makeAdminRequest<{ userPublicID: string; roleName: string }, Empty>("user.role.add"),
  removeRoleFromUser:
    makeAdminRequest<{ userPublicID: string; roleName: string }, Empty>("user.role.remove"),

  // Rating
  createSnapshot:
    makeAdminRequest<ICreateSnapshotArgs, { publicID: string }>("vendor.snapshot.create"),

  suggestedDecision: makeAdminRequest<ISuggestedDecisionArgs, ISuggestedDecision>(
    "vendor.rating.suggested_decision",
  ),

  suggestedDecisionFromSnapshot: makeAdminRequest<ISuggestedDecisionArgs, ISuggestedDecision>(
    "vendor.rating.suggested_decision_from_snapshot",
  ),

  applyDecision:
    makeAdminRequest<
      {
        snapshotPublicID: string;
        note: INote;
        notifyCustomer: boolean;
        putOnHold: boolean;
        canTrade?: boolean;
      },
      Empty
    >("vendor.decision.apply"),

  vendorDemo:
    makeAdminRequest<
      IVendorDemoArgs,
      {
        publicID: string;
        inviteCode?: string;
        sessionSwapped: boolean;
      }
    >("demo.vendor"),

  investorDemo:
    makeAdminRequest<
      { isDemo: boolean },
      {
        // Might not need anything here.
        publicID: string;
      }
    >("demo.investor"),

  updateVendorPayment:
    makeAdminRequest<
      {
        publicID: string;
        internalPaymentID?: string;
      },
      Empty
    >("vendor_payment.update"),

  initiateVendorPayment:
    makeAdminRequest<
      {
        publicID: string;
      },
      Empty
    >("vendor_payment.initiate"),

  createNote:
    makeAdminRequest<
      {
        vendorPublicID: string;
        note: INote;
      },
      Empty
    >("vendor.note.create"),

  updateVendorCustomFields: makeAdminRequest<
    {
      id: string;
      customFields: Record<string, unknown>;
    },
    Empty
  >("vendor_custom_fields.update"),

  updateVendorMetrics:
    makeAdminRequest<
      {
        vendorID: string;
      },
      {
        jobID: string;
      }
    >("vendor.metrics.update"),

  updateUploadedVendorData: makeAdminRequest<
    {
      publicID: string;
      mutation: "confirm" | "reject" | "undo_reject" | "undo_confirm";
    },
    Empty
  >("uploaded_vendor_data.update"),

  deleteUploadedVendorData: makeAdminRequest<
    {
      publicID: string;
    },
    Empty
  >("uploaded_vendor_data.delete"),

  updateVendorRequirements: makeAdminRequest<IUpdateVendorRequirementsArgs, Empty>(
    "vendor.requirements.update",
  ),

  vendorRatingDebug: makeAdminRequest<{ vendorID: string }, IRatingDebug>("vendor.rating.debug"),

  object: makeAdminRequest<{ publicID: string }, Record<string, unknown>>("object.get"),

  backgroungJobRespawn: makeAdminRequest<{ jobID: string }, Empty>("backgroundjobs.respawn"),

  backgroungJobRestart: makeAdminRequest<{ jobID: string }, Empty>("backgroundjobs.restart"),

  backgroungJobAbort: makeAdminRequest<{ jobID: string }, Empty>("backgroundjobs.abort"),

  upsertLegalEntity: makeAdminRequest<IUpsertLegalEntityArgs & { vendorID: string }, never>(
    "settings.upsert_legal_entity",
  ),

  onboardingDemo:
    makeAdminRequest<
      Empty,
      {
        code: string;
      }
    >("demo.onboarding"),

  manualOpsDirectory:
    makeAdminRequest<
      Empty,
      {
        operations: IManualOperation[];
      }
    >("manualops.directory"),

  updateVendor: makeAdminRequest<IUpdateVendorArgs, never>("vendor.update"),

  deleteDataSource:
    makeAdminRequest<
      {
        publicID: string;
      },
      Empty
    >("data_sources.delete"),

  sendInviteToVendor:
    makeAdminRequest<
      {
        name: string;
        email: string;
        role: string;
        vendorID: string;
      },
      never
    >("invites.send"),

  search: makeAdminRequest<ISearchQuery, ISearchResult[]>("search"),

  getFileUploadUrl:
    makeAdminRequest<
      {
        vendorPublicID: string;
        fileName: string;
        contentType: string;
      },
      { uploadURL: string; fileName: string }
    >("file_upload_url.get"),

  getFileDownloadURL:
    makeAdminRequest<
      {
        fileName: string;
      },
      {
        downloadURL: string;
      }
    >("file_download_url.get"),

  submitVendorDataFile: makeAdminRequest<
    (
      | {
          manualDataSourcePublicID: string;
        }
      | {
          manualDataSourceLabel: string;
        }
    ) & {
      vendorPublicID: string;
      model: IUploadedVendorDataSourceModel;
      fileSlug: string;
    },
    never
  >("manual_data_source.submit_uploaded_vendor_data"),

  uploadInvestorTradingMarketData: makeAdminRequest<
    {
      fileSlug: string;
    },
    Empty
  >("investor_trading_market_data.upload"),

  updateManualDataSourceLabel: makeAdminRequest<
    {
      publicID: string;
      label: string;
    },
    never
  >("manual_data_source.update_label"),

  fillSellRequest:
    makeAdminRequest<
      IFillSellOrderRequestArgs,
      {
        backgroundJobID: string;
      }
    >("trade.sell_order.fill"),

  getSellOrder:
    makeAdminRequest<
      {
        id: string;
      },
      {
        irrBPS: number;
        rating: asset_rating_enum_enum;
      }
    >("trade.sell_order.get"),

  getMatchingLimitBuyOrders: makeAdminRequest<
    {
      sellOrderID: string;
    },
    {
      sellOrderIrrBPS: number;
      limitBuyOrders: Array<ILimitBuyOrder>;
    }
  >("trade.limit_buy_orders.get"),

  cancelLimitBuyOrder: makeAdminRequest<
    {
      publicID: string;
    },
    never
  >("trade.limit_buy_order.cancel"),

  createLimitBuyOrder: makeAdminRequest<
    | {
        amountLimit: string;
        currency: string;
        assetRating: string;
        expiresAt?: string;
        minTermMonths?: number;
        maxTermMonths?: number;
        irrBPS: number;
      }
    | {
        investorID: string;
      },
    {
      publicID: string;
    }
  >("trade.limit_buy_order.create"),

  teamMembersList:
    makeAdminRequest<
      Empty,
      {
        members: Array<ITeamMember>;
      }
    >("slack.users.list"),

  teamMemberInfo: makeAdminRequest<{ id: string }, ITeamMember>("slack.user.info"),

  entertainmentCreateDeal: makeAdminRequest<
    {
      id: string;
      dealDate: string;
      sellerName: string;
      sellerWebsite: string;
      currency: string;
      payoutAmount: number;
      payoutDate: string;
      paymentsCSVFile: string;
    },
    Empty
  >("entertainment.deal.create"),
};
