/* eslint-disable react/style-prop-object */
import React from "react";

export const Accounting: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2H17V18H3V2ZM15 8H5V4H15V8ZM5 12H7V10H5V12ZM7 16H5V14H7V16ZM9 12H11V10H9V12ZM11 16H9V14H11V16ZM13 10V16H15V10H13Z"
      fill="#DFE0E2"
    />
  </svg>
);
