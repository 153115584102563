/* eslint-disable react/style-prop-object */
import React from "react";

export const Revenues: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 17H18" stroke="#DFE0E2" strokeWidth="1.5" />
    <path d="M5 15V10" stroke="#DFE0E2" strokeWidth="4" />
    <path d="M10 15V6" stroke="#DFE0E2" strokeWidth="4" />
    <path d="M15 15V2" stroke="#DFE0E2" strokeWidth="4" />
  </svg>
);
