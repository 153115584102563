import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";

import { integration_type_enum_enum } from "~src/__generated__/graphql/types";
import { adminRequests } from "~src/admin/requests";
import { IFile } from "~src/designSystem/atoms/Dropzone/useFilesUploading";
import { VendorDropzone } from "~src/designSystem/atoms/Dropzone/VendorDropzone";
import { OldInputGoUseTextfield } from "~src/designSystem/deprecated/OldInput";
import { Success } from "~src/designSystem/icons/Success";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { UploadedVendorDataSourceMap } from "~src/shared/constants";
import { capitalizeFirstLetter } from "~src/shared/helpers";
import { WarningMessage } from "~src/shared/informationals/WarningMessage";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource, IUploadedVendorDataSourceModel } from "~src/shared/types";
import { vendorRequests } from "~src/vendor/requests";
import { Field } from "~src/vendor/settingsCompany/components/Field";

import { DataSourceInformationLayout } from "../components/dataSourceInformationLayout";
import { DataSourceLayout } from "../components/dataSourceLayout";
import { DataSourceLayoutButtonSection } from "../components/dataSourceLayout/DataSourceLayoutButtonSection";
import {
  CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_BACK_BUTTON_CLICKED_EVENT,
  CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_DOCS_LINK_CLICKED_EVENT,
  CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_FILE_UPLOADED_EVENT,
  CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_TEMPLATE_LINK_CLICKED_EVENT,
  CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_UPLOAD_BUTTON_CLICKED_EVENT,
  useDataSourceCSVUploadTracking,
} from "../tracking/useDataSourceCSVUploadTracking";
import { uploadFileHelpMap } from "./CSVUploadExplainer";

export interface IStepCSVUploadManagerProps {
  label?: string;
  vendorID?: string;
  isLabelEditable?: boolean;
  isAdminRequest?: boolean;
  source: IConnectDataSourceFlowSource;
  onClose?: () => void;
  onSuccess?: () => void;
}

type IForm = {
  label: string;
  model: IUploadedVendorDataSourceModel;
};

export const CSVUploader: React.FC<IStepCSVUploadManagerProps> = ({
  label: initialLabel = "",
  vendorID,
  isLabelEditable = true,
  isAdminRequest = false,
  source,
  onSuccess,
}) => {
  const { addAndOpenStepperDialog, removeTopOfStack, clearStepperStack } = useStepper();

  const [files, setFiles] = useState<readonly IFile[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const trackUploaderEvent = useDataSourceCSVUploadTracking(source);

  const initialValues: IForm = {
    label: initialLabel,
    model: "subscriptions",
  };

  // Delete error message if all files are removed
  useEffect(() => {
    if (files.length <= 0) {
      setSubmitError(null);
    }
  }, [files.length]);

  const onSubmit = async (form: IForm) => {
    setIsSubmitting(true);

    trackUploaderEvent(
      CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_UPLOAD_BUTTON_CLICKED_EVENT,
      form,
    );

    const { label, model } = form;

    const requestParameters = {
      manualDataSourceLabel: label,
      model,
      fileSlug: files[0]?.fileName ?? "",
    };

    // network request to upload file
    const { ok, error } = await callRequest(
      isAdminRequest
        ? adminRequests.submitVendorDataFile({
            ...requestParameters,
            vendorPublicID: vendorID ?? "",
          })
        : vendorRequests.submitVendorDataFile(requestParameters),
      { handleRPCError: (err) => err.status === 400 },
    );

    setIsSubmitting(false);
    if (ok) {
      onSuccess?.();
      addAndOpenStepperDialog({
        component: (
          <DataSourceInformationLayout
            heading="Revenue streams added"
            description="Nicely done! You’ve successfully added your revenue streams."
            imageSrc={Success}
            primaryButtonText="Done"
            primaryButtonAction={clearStepperStack}
          />
        ),
      });
    } else {
      setSubmitError(error?.errorMessage ?? "Something went wrong.");
    }
  };

  const validationSchema: yup.SchemaOf<IForm> = useMemo(
    () =>
      yup.object({
        label: yup.string().trim().required(),
        model: yup
          .mixed<IUploadedVendorDataSourceModel>()
          .oneOf(Object.keys(UploadedVendorDataSourceMap) as IUploadedVendorDataSourceModel[])
          .required(),
      }),
    [],
  );

  const formik = useFormik<IForm>({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const uploadFileHelp = uploadFileHelpMap[formik.values.model];

  return (
    <DataSourceLayout
      heading="Upload your revenue streams"
      description={
        <div>
          We've standardized our{" "}
          <a
            href={uploadFileHelp.csvTemplateURL}
            onClick={() => {
              trackUploaderEvent(
                CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_TEMPLATE_LINK_CLICKED_EVENT,
                formik.values,
              );
            }}
            target="_blank"
          >
            CSV template
          </a>
          . If you're having trouble with your upload, review our{" "}
          <a
            href={uploadFileHelp.csvPDFGuideURL}
            onClick={() => {
              trackUploaderEvent(
                CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_DOCS_LINK_CLICKED_EVENT,
                formik.values,
              );
            }}
            target="_blank"
          >
            documentation
          </a>{" "}
          for details on how to fill out the template.
        </div>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Gridbox>
          <Field>
            <label htmlFor="label">Data Source Name</label>
            <OldInputGoUseTextfield
              placeholder='E.g. "QuickBooks Desktop" or "Internal System"'
              name="label"
              value={formik.values.label}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.label}
              disabled={!isLabelEditable}
            />
          </Field>
          <VendorDropzone
            directory={integration_type_enum_enum.billing_manager}
            accepts={[
              "text/csv",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ]}
            extensions={["csv"]}
            onFilesUpdate={(newFiles) => {
              trackUploaderEvent(
                CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_FILE_UPLOADED_EVENT,
                formik.values,
              );
              setFiles(newFiles);
            }}
            canRemoveFiles
            maxNumOfFiles={1}
            vendorPublicID={vendorID}
          />
          {submitError !== null && (
            <WarningMessage type="error" message={capitalizeFirstLetter(submitError)} />
          )}
          <DataSourceLayoutButtonSection
            secondaryButtonText="Back"
            secondaryButtonAction={(e) => {
              e.preventDefault();

              trackUploaderEvent(
                CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_BACK_BUTTON_CLICKED_EVENT,
                formik.values,
              );

              removeTopOfStack();
            }}
            primaryButtonText="Upload"
            isPrimaryButtonDisabled={files.length === 0 || isSubmitting || !formik.isValid}
            isPrimaryButtonLoading={isSubmitting}
            isPrimarySubmit
          />
        </Gridbox>
      </form>
    </DataSourceLayout>
  );
};
