import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { Box } from "~src/designSystem/atoms/Box";
import { Popover } from "~src/designSystem/atoms/Popover";
import { TwoPartCard } from "~src/designSystem/atoms/TwoPartCard";
import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { LoadingSpinner } from "~src/designSystem/loading/LoadingSpinner";
import { IndicatorIcon } from "~src/shared/animations/IndicatorIcon";
import { ErrorComponent } from "~src/shared/errors/components/ErrorComponent";

import { IRequirement } from "../../hooks/useFetchDataSourceRequirements";
import { CaughtUp } from "../CaughtUp";
import { NotificationItem } from "../NotificationItem";

export type IBadgePresentationProps = {
  loading: boolean;
  requirements?: IRequirement[];
  onRelinkNotificationClick: () => void;
  onConnectAccountingClick: () => void;
  onConnectBillingClick: () => void;
  onConnectBankClick: () => void;
  onOpen?: () => void;
};

export const NotificationsBadgePresentation: React.FC<IBadgePresentationProps> = (props) => {
  const hasNotification = props.requirements !== undefined && props.requirements.length !== 0;

  return (
    <Popover placement="bottom right" offset={8} onClick={props.onOpen}>
      <ButtonWrapper hasNotification={hasNotification}>
        <BlueprintIcon icon="notifications" />
        {hasNotification && (
          <PulsingDot>
            <IndicatorIcon color="red" />
          </PulsingDot>
        )}
      </ButtonWrapper>
      {({ closePopover }) => (
        <NotificationsBadgeContent
          {...props}
          loading={props.loading}
          closePopover={closePopover}
          requirements={props.requirements}
        />
      )}
    </Popover>
  );
};

export const NotificationsBadgeContent: React.FC<
  IBadgePresentationProps & { closePopover: () => void }
> = (props) => {
  const onNotificationClickFn = (req: IRequirement) => {
    switch (req.type) {
      case "additional":
      case "initial": {
        if (req.category === "revenue") {
          return props.onConnectBillingClick;
        }
        if (req.category === "bank") {
          return props.onConnectBankClick;
        }
        return props.onConnectAccountingClick;
      }
      case "relink":
      default: {
        return props.onRelinkNotificationClick;
      }
    }
  };

  return (
    <ContentWrapper>
      <ContentHeader padding="12px">Notifications</ContentHeader>
      {(() => {
        if (props.requirements === undefined || props.loading) {
          return (
            <Flexbox alignItems="center" justifyContent="center" height="140px">
              <LoadingSpinner />
            </Flexbox>
          );
        }

        if (props.requirements.length !== 0) {
          return (
            <Box paddingY="4px" overflow="auto" maxHeight="360px">
              {props.requirements.map((req) => (
                <NotificationItem
                  key={JSON.stringify(req)}
                  requirement={req}
                  onClick={() => {
                    props.closePopover();
                    onNotificationClickFn(req)();
                  }}
                />
              ))}
            </Box>
          );
        }

        return <CaughtUp />;
      })()}
    </ContentWrapper>
  );
};

export const NotificationsBadgeError: React.FC = () => (
  <Popover placement="bottom right" offset={8}>
    <ButtonWrapper hasNotification={false}>
      <BlueprintIcon icon="notifications" />
    </ButtonWrapper>
    <NotificationsBadgeErrorContent />
  </Popover>
);

export const NotificationsBadgeErrorContent: React.FC = () => (
  <ContentWrapper>
    <ContentHeader padding="12px">Notifications</ContentHeader>
    <Box height="140px">
      <ErrorComponent />
    </Box>
  </ContentWrapper>
);

const ButtonWrapper = styled.button<{ hasNotification: boolean }>`
  position: relative;
  background: transparent;
  height: 32px;
  width: 36px;
  /* We need this, otherwise it'll compress with a smaller header. */
  min-width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;

  cursor: pointer;

  transition: ${(props) => props.theme.transition};
  color: ${({ theme }) => theme.components.NavBar.Texticon.inactive};
  border-radius: 2px;

  ${(props) =>
    props.hasNotification &&
    css`
      color: ${props.theme.components.NavBar.Texticon.active};
    `};

  &:hover {
    background-color: ${(props) => props.theme.components.NavBar.Texticon.background.hover};
    color: ${({ theme }) => theme.components.NavBar.Texticon.active};
  }
`;

const ContentWrapper = styled(TwoPartCard)`
  background-color: ${(props) => props.theme.components.Notifications.Background.color};
  width: 340px;
  border-radius: ${(props) => props.theme.borderRadii.xs};
`;

const ContentHeader = styled(Box)`
  color: ${(props) => props.theme.components.Notifications.Header.color};
`;

const PulsingDot = styled.div`
  position: absolute;
  top: 2px;
  left: 12.5px;
`;
