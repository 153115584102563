import { IImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import { ICodatPlatformKey, IIntegrationType } from "~src/shared/types";

import { CSVLogo } from "../manual/CSVLogo";
import { Netsuite } from "./logos/Netsuite";
import { Quickbooks } from "./logos/Quickbooks";
import { Sageone } from "./logos/Sageone";
import { Xero } from "./logos/Xero";
import { Zohobooks } from "./logos/Zohobooks";

export type IAccountingPlatformKey = ICodatPlatformKey | "csv";
export interface IAccountingPlatform {
  key: IAccountingPlatformKey;
  name: string;
  logo: IImageOrSVG;
  subtitle: string;
  type: IIntegrationType;
}
export const ACCOUNTING_PLATFORMS: Readonly<
  {
    [key in ICodatPlatformKey]: IAccountingPlatform;
  }
> = {
  vxvy: {
    key: "vxvy",
    name: "Freshbooks",
    logo: "/static/assets/logos/freshbooks.png",
    subtitle: "freshbooks.com",
    type: "accounting",
  },
  wvzu: {
    key: "wvzu",
    name: "KashFlow",
    logo: "/static/assets/logos/kashflow.png",
    subtitle: "kashflow.com",
    type: "accounting",
  },
  akxx: {
    key: "akxx",
    name: "Oracle NetSuite",
    logo: Netsuite,
    subtitle: "netsuite.com",
    type: "accounting",
  },
  qhyg: {
    key: "qhyg",
    name: "Quickbooks",
    logo: Quickbooks,
    subtitle: "quickbooks.com",
    type: "accounting",
  },
  tgff: {
    key: "tgff",
    name: "Sage",
    logo: Sageone,
    subtitle: "sageone.com",
    type: "accounting",
  },
  rwuv: {
    key: "rwuv",
    name: "Zoho Books",
    logo: Zohobooks,
    subtitle: "zohobooks.com",
    type: "accounting",
  },
  pbtz: {
    key: "pbtz",
    name: "Wave",
    logo: "/static/assets/logos/wave.png",
    subtitle: "waveapps.com",
    type: "accounting",
  },
  gbol: {
    key: "gbol",
    name: "Xero",
    logo: Xero,
    subtitle: "xero.com",
    type: "accounting",
  },
};

export const CSV_ACCOUNTING_MANAGER: IAccountingPlatform = {
  key: "csv",
  name: "CSV",
  logo: CSVLogo,
  subtitle: "Upload CSV",
  type: "accounting",
};
