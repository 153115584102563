import styled from "@emotion/styled";

import { Flexbox } from "~src/designSystem/layout/Flexbox";

interface IProps {
  children: React.ReactNode;
  gap?: string;
}

export const DataSourceModalWrapper: React.FC<IProps> = ({ gap, children }) => {
  return (
    <OuterWrapper>
      <InnerWrapper gap={gap}>{children}</InnerWrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div`
  width: 100%;
`;

const InnerWrapper = styled(Flexbox)`
  max-width: 585px;
  margin-inline: auto;
`;
