import React, { useCallback } from "react";

import { useUser } from "~src/shared/auth/useUser";
import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { ConnectDataSourceSearch } from "../../components/search";
import { CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_CLOSED_OUT_EVENT } from "../../tracking/useAccountingCSVUploadTracking";
import { CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_CLOSE_EVENT } from "../../tracking/utils";
import { IAccountingPlatform } from "../accountingPlatforms";
import { ConnectAccountingRedirect } from "../ConnectAccountingRedirect";
import { UploadAccountingCSV } from "../UploadAccountingCSV";

interface IUseConnectAccountingArgs {
  redirectPath: IAllowedRedirectPath;
  onSuccess?: () => Promise<void>;
  source: IConnectDataSourceFlowSource;
}

export const useConnectAccounting = ({
  onSuccess,
  redirectPath,
  source,
}: IUseConnectAccountingArgs): (() => void) => {
  const { addAndOpenStepperDialog, clearStepperStack } = useStepper();
  const { trackEvent } = useAnalytics();

  const { country } = useUser().vendor;

  const onSelect = useCallback(
    async (platform: IAccountingPlatform) => {
      let onCloseTrackingEvent: ISegmentTrackEvent | null;
      let nextStepComponent;
      if (platform.key === "csv") {
        onCloseTrackingEvent =
          CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_CLOSED_OUT_EVENT[source];
        nextStepComponent = <UploadAccountingCSV source={source} onSuccess={onSuccess} />;
      } else {
        onCloseTrackingEvent = CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_CLOSE_EVENT[source];
        nextStepComponent = (
          <ConnectAccountingRedirect platform={platform} redirectPath={redirectPath} />
        );
      }

      addAndOpenStepperDialog({
        component: nextStepComponent,
        config: {
          title: "Sync your accounting platform",
          onCloseOverride: () => {
            if (onCloseTrackingEvent != null) {
              trackEvent(onCloseTrackingEvent);
            }
            clearStepperStack();
          },
        },
      });
    },
    [addAndOpenStepperDialog, clearStepperStack, onSuccess, redirectPath, source, trackEvent],
  );
  return () =>
    addAndOpenStepperDialog({
      component: (
        <ConnectDataSourceSearch
          country={country ?? undefined}
          type="accounting"
          onSelect={onSelect}
          source={source}
        />
      ),
      config: {
        title: "Sync your accounting platform",
      },
    });
};
