import styled from "@emotion/styled";

export const Field = styled.fieldset`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 8px;
    color: ${(props) => props.theme.components.Input.label.default};
    font-size: 12px;
  }
`;
