import styled from "@emotion/styled";

import { withBorderStyles, withBoxStyles } from "~src/shared/helpers/sharedComponentProps";

import { Flexbox, IFlexboxProps } from "../../layout/Flexbox";

export type ICardProps = {
  isActive?: boolean;
  className?: string;
} & IFlexboxProps;

/**
 * A flexible container for general use. Includes default border, border-radius, and padding styles.
 */
export const Card: React.FC<ICardProps> = styled(Flexbox)<ICardProps>`
  padding: ${(props) => props.padding ?? props.theme.spacing.lg};
  border-radius: ${(props) => props.borderRadius ?? "12px"};

  ${(props) => withBoxStyles(props)}
  ${({ border = true, theme, ...rest }) =>
    withBorderStyles({ border, ...rest }, theme.components.Card.Base.border)}

  background: ${(props) =>
    props.isActive === true && props.theme.components.Card.Base.activeBackground};
  border-color: ${(props) =>
    props.isActive === true && props.theme.components.Card.Base.activeBackground};
`;
