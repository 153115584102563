import { useCallback, useMemo } from "react";

import { data_source_provider_enum_enum } from "~src/__generated__/graphql/types";
import { IImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";

import { useUser } from "../auth/useUser";
import { ICodatPlatformKey } from "../types";
import {
  useUseVendorDataSources,
  UseVendorDataSources,
} from "./__generated__/useVendorDataSources";
import { ACCOUNTING_PLATFORMS } from "./accounting/accountingPlatforms";
import { BILLING_MANAGERS, IBillingManagerKey } from "./billing/billingManagers";
import { FetchVendorDataSourcesError } from "./errors";
import { CSVLogo } from "./manual/CSVLogo";

export type IDataSourceProvider = `${data_source_provider_enum_enum}` | "accounting_csv";

export type IDataSourceCommon = {
  publicID: string;
  provider: IDataSourceProvider;
  displayName: string;
  displaySubheading?: string;
  lastSyncCompletedAt?: string;
  userActionRequiredAt?: string;
  logo: IImageOrSVG;
  isAccounting: boolean;
  isBank: boolean;
  isRevenue: boolean;
  dashboardURL?: string;
};

export interface IDataSource_Plaid extends IDataSourceCommon {
  provider: "plaid";
  dashboardURL: string;
  plaidItemPublicID: string;
}
export interface IDataSource_Codat extends IDataSourceCommon {
  provider: "codat";
  platformKey: ICodatPlatformKey;
  linkURL: string;
  dashboardURL: string;
  codatCompanyPublicID: string;
}
export interface IDataSource_Manual extends IDataSourceCommon {
  provider: "manual";
}
export interface IAccounting_CSV extends IDataSourceCommon {
  provider: "accounting_csv";
}
export interface IDataSource_Billing extends IDataSourceCommon {
  provider: "apple" | "recurly" | "chargebee" | "chargify" | "gocardless" | "paypal" | "stripe";
  vendorNumber?: string;
  subdomain?: string;
}

export type IDataSource =
  | IDataSource_Plaid
  | IDataSource_Codat
  | IDataSource_Billing
  | IDataSource_Manual
  | IAccounting_CSV;

export type IUseVendorDataSources = {
  data: {
    dataSources: IDataSource[];
    hasUploadedAccountingCSV: boolean;
    hasUploadedBillingManagerCSV: boolean;
    isActivated: boolean;
    isAdditionalAccountingLinkRequired: boolean;
    isAdditionalBankLinkRequired: boolean;
    isAdditionalBillingManagerRequired: boolean;
  };
  loading: boolean;
  error?: FetchVendorDataSourcesError;
  refetch: () => Promise<void>;
};

export const useVendorDataSources = (vendorID?: string): IUseVendorDataSources => {
  const { vendorPublicID: userVendorID } = useUser();
  const vendorPublicID = vendorID ?? userVendorID;

  const { data, loading, error, refetch } = useUseVendorDataSources({
    variables: { vendorID: vendorPublicID },
    fetchPolicy: "cache-and-network",
  });

  const doRefetch = useCallback(async () => {
    await refetch({ vendorID: vendorPublicID });
  }, [vendorPublicID, refetch]);

  const wrappedError = useMemo(() => {
    if (error === undefined) {
      return error;
    }

    return new FetchVendorDataSourcesError("Failed to fetch vendor data sources.", {
      cause: error,
    });
  }, [error]);

  return useMemo(() => {
    const vendor = data?.vendors[0];
    const dataSources: IDataSource[] =
      vendor?.data_sources.map((ds) => {
        switch (ds.provider) {
          case "plaid":
            return makePlaidDataSource(ds);
          case "codat":
            return makeCodatDataSource(ds);
          case "manual":
            return makeManualDataSource(ds);
          case "apple":
            return makeBillingDataSource(ds, "apple");
          case "chargebee":
            return makeBillingDataSource(ds, "chargebee");
          case "chargify":
            return makeBillingDataSource(ds, "chargify");
          case "gocardless":
            return makeBillingDataSource(ds, "gocardless");
          case "paypal":
            return makeBillingDataSource(ds, "paypal");
          case "recurly":
            return makeBillingDataSource(ds, "recurly");
          case "stripe":
            return makeBillingDataSource(ds, "stripe");
          default: {
            throw Error(`Unknown data source provider, provider=${ds.provider}`);
          }
        }
      }) ?? [];

    // Note: We only care about capturing/showing in the UI that the vendor
    // has at least one accounting CSV upload. Therefore we just pick the first.
    const accountingCSV = makeAccountingCSV(vendor?.uploaded_vendor_data[0]);
    if (accountingCSV !== undefined) {
      dataSources.push(accountingCSV);
    }

    return {
      data: {
        dataSources,
        hasUploadedAccountingCSV: vendor?.has_uploaded_accounting_csv ?? false,
        hasUploadedBillingManagerCSV: vendor?.has_uploaded_billing_manager_csv ?? false,
        isActivated: vendor?.is_activated ?? false,
        isAdditionalAccountingLinkRequired: vendor?.is_additional_accounting_link_required ?? false,
        isAdditionalBankLinkRequired: vendor?.is_additional_bank_link_required ?? false,
        isAdditionalBillingManagerRequired: vendor?.is_additional_billing_manager_required ?? false,
      },
      loading,
      error: wrappedError,
      refetch: doRefetch,
    };
  }, [data?.vendors, loading, wrappedError, doRefetch]);
};

type UploadedVendorData = UseVendorDataSources["vendors"][number]["uploaded_vendor_data"][number];
type DataSource = UseVendorDataSources["vendors"][number]["data_sources"][number];

const makeDataSourceCommon = (ds: DataSource): Omit<IDataSourceCommon, "provider" | "logo"> => ({
  publicID: ds.public_id,
  displayName: ds.display_name,
  displaySubheading: ds.display_subheading ?? undefined,
  lastSyncCompletedAt: ds.last_sync_completed_at ?? undefined,
  userActionRequiredAt: ds.user_action_required_at ?? undefined,
  isAccounting: ds.is_accounting,
  isBank: ds.is_bank,
  isRevenue: ds.is_revenue,
});

const makeBillingDataSource = (
  ds: DataSource,
  provider: Exclude<IBillingManagerKey, "csv">,
): IDataSource_Billing => {
  const subdomain = (() => {
    switch (provider) {
      case "chargebee": {
        return ds.chargebee_data_source?.subdomain;
      }
      case "chargify":
        return ds.chargify_data_source?.subdomain;
      case "recurly":
        return ds.recurly_data_source?.subdomain;
      default: {
        return undefined;
      }
    }
  })();

  const vendorNumber = (() => {
    switch (provider) {
      case "apple": {
        return ds.apple_data_source?.vendor_number;
      }
      default: {
        return undefined;
      }
    }
  })();

  const dashboardURL = (() => {
    const stripeAccountID = ds.stripe_data_source?.stripe_account_id;
    if (stripeAccountID != null) {
      return `https://dashboard.stripe.com/${stripeAccountID}`;
    }
    return undefined;
  })();

  return {
    ...makeDataSourceCommon(ds),
    provider,
    logo: BILLING_MANAGERS[provider].logo,
    subdomain,
    dashboardURL,
    vendorNumber,
  };
};

const makePlaidDataSource = (ds: DataSource): IDataSource_Plaid => {
  const plaidItem = ds.plaid_item;
  if (plaidItem == null) {
    throw new Error("missing plaid_item");
  }
  return {
    ...makeDataSourceCommon(ds),
    provider: "plaid",
    logo:
      plaidItem.institution_logo != null
        ? `data:image/png;base64,${plaidItem.institution_logo}`
        : "",
    dashboardURL: `https://dashboard.plaid.com/activity/logs?identifier=${plaidItem.item_id}`,
    plaidItemPublicID: plaidItem.public_id,
  };
};

const makeCodatDataSource = (ds: DataSource): IDataSource_Codat => {
  const codatCompany = ds.codat_company;
  if (codatCompany == null) {
    throw new Error("missing codat_company");
  }
  return {
    ...makeDataSourceCommon(ds),
    isRevenue: false,
    provider: "codat",
    linkURL: codatCompany.link_url,
    platformKey: codatCompany.platform_key,
    logo: ACCOUNTING_PLATFORMS[codatCompany.platform_key].logo,
    dashboardURL: `https://portal.codat.io/#/data/${codatCompany.codat_id}`,
    codatCompanyPublicID: codatCompany.public_id,
  };
};

const makeManualDataSource = (ds: DataSource): IDataSource_Manual => {
  return {
    ...makeDataSourceCommon(ds),
    provider: "manual",
    logo: CSVLogo,
  };
};

const makeAccountingCSV = (vendorData?: UploadedVendorData): IAccounting_CSV | undefined => {
  if (vendorData === undefined) {
    return undefined;
  }
  return {
    publicID: vendorData.public_id,
    provider: "accounting_csv",
    displayName: "CSV",
    lastSyncCompletedAt: vendorData.created_at,
    userActionRequiredAt: undefined,
    logo: CSVLogo,
    isAccounting: true,
    isBank: false,
    isRevenue: false,
  };
};

graphql`
  query UseVendorDataSources($vendorID: String!) {
    vendors(where: { public_id: { _eq: $vendorID } }) {
      public_id

      has_uploaded_accounting_csv
      has_uploaded_billing_manager_csv
      is_activated
      is_additional_accounting_link_required
      is_additional_bank_link_required
      is_additional_billing_manager_required

      # Legacy Accounting CSVs
      uploaded_vendor_data(
        where: { data_type: { _eq: accounting } }
        order_by: { created_at: desc }
        limit: 1
      ) {
        ...UseVendorDataSources_csv
      }

      # Data Sources
      data_sources(order_by: { created_at: asc }) {
        # Common fields for all data sources
        ...UseVendorDataSources_common

        # Billing data sources
        stripe_data_source {
          public_id
          stripe_account_id
        }
        apple_data_source {
          public_id
          vendor_number
        }
        chargebee_data_source {
          public_id
          subdomain
        }
        chargify_data_source {
          public_id
          subdomain
        }
        recurly_data_source {
          public_id
          subdomain
        }

        # Manual data sources
        manual_data_source {
          ...UseVendorDataSources_manual
        }

        # Accounting (Codat) data sources
        codat_company {
          ...UseVendorDataSources_codat
        }

        # Bank (Plaid) data sources
        plaid_item {
          ...UseVendorDataSources_plaid
        }
      }
    }
  }

  # Bank (Plaid)
  fragment UseVendorDataSources_plaid on plaid_items {
    public_id
    item_id
    institution_logo
  }

  # Accounting (Codat)
  fragment UseVendorDataSources_codat on codat_companies {
    public_id
    codat_id
    link_url
    platform_key
  }

  # Billing (manual data sources)
  fragment UseVendorDataSources_manual on manual_data_sources {
    public_id
    label

    uploaded_vendor_data {
      public_id
      created_at
      updated_at
      resource_slug
      data_type
      confirmed_at
    }
  }

  # Common fields for all data sources
  fragment UseVendorDataSources_common on data_sources {
    public_id
    provider

    display_name
    display_subheading
    last_sync_completed_at
    user_action_required_at

    is_accounting
    is_bank
    is_revenue
  }

  # Legacy Accounting CSVs
  fragment UseVendorDataSources_csv on uploaded_vendor_data {
    public_id
    created_at
  }
`;
