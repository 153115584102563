import styled from "@emotion/styled";
import { motion, Variants } from "framer-motion";
import React, { ReactNode } from "react";
import { FaInfoCircle } from "react-icons/fa";

import { ReallyOldParagraphDontUse } from "~src/designSystem/deprecated/Paragraph.old";

interface IProps {
  message: string | null;
  type?: "error";
  margin?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
}

const fadeInDown: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    height: "auto",
    transition: { delayChildren: 0.2 },
  },
  hidden: { opacity: 0, y: -10, height: 0 },
};

export const WarningMessage: React.FC<IProps> = (props: IProps) => {
  const { message } = props;
  if (message !== null && message !== "") {
    return (
      <StyledContainer
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={fadeInDown}
        {...props}
      >
        <FaInfoCircle style={{ marginRight: "8px" }} />
        <ReallyOldParagraphDontUse
          style={{
            // Support messages with '\n' returned from the server
            whiteSpace: "pre-line",
          }}
          margin="0"
        >
          {message}
        </ReallyOldParagraphDontUse>
      </StyledContainer>
    );
  }
  return null;
};

const StyledContainer = styled(motion.div)<IProps>`
  display: flex;

  min-height: fit-content;
  height: fit-content;

  padding: 1em 20px;
  font-size: 13px;
  line-height: 14px;
  border-radius: 4px;
  align-items: center;
  background: ${(props) => (props.type === "error" ? "#DD414133" : "#fff6dd")};
  color: #dd4141;
  margin: ${(props) => props.margin ?? "10px 0"};
`;
