import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";

import { useUser } from "~src/shared/auth/useUser";
import { useSetDarkMode } from "~src/shared/darkMode/useDarkMode";

import { NavItem } from "./NavItem";

export const DarkModeSwitcher: React.FC = () => {
  const { darkMode, setDarkMode } = useSetDarkMode();
  const user = useUser();

  if (!user.email.endsWith("missionctrl.com") && !user.email.endsWith("pipe.com")) {
    return null;
  }

  return (
    <NavItem
      onClick={() => {
        setDarkMode(!darkMode);
      }}
      title={darkMode ? "Light Mode" : "Dark Mode"}
      icon={darkMode ? <FaSun /> : <FaMoon />}
    />
  );
};
