/* eslint-disable react/style-prop-object */
import React from "react";

export const Bank: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.25 16.5V8" stroke="#DFE0E2" strokeWidth="1.5" />
    <path d="M11.75 16.5V8" stroke="#DFE0E2" strokeWidth="1.5" />
    <path d="M8.25 16.5V8" stroke="#DFE0E2" strokeWidth="1.5" />
    <path d="M4.75 16.5V8" stroke="#DFE0E2" strokeWidth="1.5" />
    <path d="M2 17H18" stroke="#DFE0E2" strokeWidth="1.5" />
    <path d="M2 8L10 2L18 8V9.5H2V8Z" fill="#DFE0E2" />
  </svg>
);
