import { useState } from "react";

export interface IFile {
  file: File;
  /**
   * Backend object name
   */
  fileName?: string;
  percent: number;
  state: "success" | "error" | "loading";
  url?: string;
}

interface IUseFilesUploading {
  setPercent: (file: File, percent: number, fileName: string) => void;
  onSuccess: (file: File, url: string, fileName: string) => void;
  onError: (file: File, url?: string, fileName?: string) => void;
  removeFile: (file: File) => void;
  files: IFile[];
}

export const useFilesUploading = (): IUseFilesUploading => {
  const [files, setFiles] = useState<IFile[]>([]);

  const setFile = (file: File, ifile: IFile) => {
    const index = files.findIndex((f) => f.file === file);
    if (index === -1) {
      setFiles([...files, ifile]);
    } else {
      setFiles([...files.slice(0, index), ifile, ...files.slice(index + 1)]);
    }
  };

  const removeFile = (file: File) => {
    setFiles(files.filter((f) => f.file !== file));
  };

  return {
    setPercent: (file, percent, fileName) => {
      setFile(file, { file, percent, state: "loading", fileName });
    },
    onSuccess: (file, url, fileName) => {
      setFile(file, { file, percent: 1, state: "success", url, fileName });
    },
    onError: (file, url, fileName) => {
      setFile(file, { file, percent: 0, state: "error", url, fileName });
    },
    removeFile,
    files,
  };
};
