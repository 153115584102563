import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transparentize } from "polished";
import React from "react";
import { DropzoneOptions, DropzoneRootProps, DropzoneState, useDropzone } from "react-dropzone";

import { StyledLabel } from "~src/designSystem/deprecated/OldOldInput";

export type IDropzoneProps = {
  label?: string;
  dropzoneOptions?: DropzoneOptions;
  error?: string;
  hideFileList?: boolean;
  placeholder?: React.ReactNode;
  hideDropzone?: boolean;
  children?: ({ dropzone }: { dropzone: DropzoneState }) => React.ReactNode;
};

/** react-dropzone */
export const Dropzone: React.FC<IDropzoneProps> = ({
  children,
  placeholder,
  label,
  dropzoneOptions,
  hideDropzone,
  error,
  hideFileList,
}: IDropzoneProps) => {
  const dropzone = useDropzone(dropzoneOptions);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    dropzone;

  const rootProps = {
    className: "test",
    isDragActive,
    isDragAccept,
    isDragReject,
  };

  return (
    <>
      <Container>
        {label != null && <StyledLabel error={error}>{label}</StyledLabel>}
        <DropzoneRoot {...getRootProps(rootProps)} hidden={hideDropzone === true}>
          <input {...getInputProps()} />
          {placeholder ?? <p>Drag subscription CSV here to upload.</p>}
        </DropzoneRoot>
        {hideFileList !== true && (
          <aside>
            {acceptedFiles.map((file) => (
              <li key={file.name}>
                {file.name} - {file.size} bytes
              </li>
            ))}
          </aside>
        )}
      </Container>
      {children?.({ dropzone })}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 1rem;
  }

  & > em {
    font-size: 0.8rem;
  }
`;

const DropzoneRoot = styled.div<DropzoneRootProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 4px;
  min-height: 155px;
  border: 1px dashed
    ${(props) => {
      switch (true) {
        case props.isDragAccept === true:
          return props.theme.components.Dropzone.border.accept;
        case props.isDragReject === true:
          return props.theme.components.Dropzone.border.reject;
        case props.isDragActive === true:
        default:
          return props.theme.components.Dropzone.border.default;
      }
    }};
  background-color: ${(props) => props.theme.components.Dropzone.background.default};
  color: ${(props) => props.theme.components.Dropzone.text.default};
  outline: none;
  transition: border 0.24s ease-in-out;
  transition: background-color 0.24s ease-in-out;

  ${(props) =>
    props.isDragReject === true
      ? css`
          background-color: ${transparentize(
            0.89,
            props.theme.components.Dropzone.background.reject,
          )};
        `
      : props.isDragActive === true &&
        css`
          background-color: ${props.theme.components.Dropzone.background.active};
        `}

  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.components.Dropzone.border.hover};
    color: ${(props) => props.theme.components.Dropzone.text.hover};
    background-color: ${(props) => props.theme.components.Dropzone.background.hover};
  }

  &:focus {
    border-color: ${(props) => props.theme.components.Dropzone.border.active};
  }

  &.disabled {
    opacity: 0.6;
  }
`;
