import { ApplicationError, IErrorOptions } from "~src/shared/errors/taxonomy";

export class InvalidRequirementError extends ApplicationError {
  constructor(message: string, options?: IErrorOptions) {
    super(message, {
      ...options,
      code: "DATA_SOURCES_REQUIREMENTS/INVALID_REQUIREMENT",
    });
  }
}
