import React from "react";

import { Upload } from "~src/designSystem/icons/Upload";
import {
  CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_BACK_BUTTON_CLICKED_EVENT,
  CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_CONTINUE_BUTTON_CLICKED_EVENT,
  CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_DOCS_LINK_CLICKED_EVENT,
  CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_TEMPLATE_LINK_CLICKED_EVENT,
  useDataSourceUploadExplainerTracking,
} from "~src/shared/dataSources/tracking/useDataSourceUploadExplainerTracking";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { useAnalytics } from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource, IUploadedVendorDataSourceModel } from "~src/shared/types";

import { DataSourceInformationLayout } from "../components/dataSourceInformationLayout";
import { CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_CLOSED_OUT_EVENT } from "../tracking/useDataSourceCSVUploadTracking";
import { CSVUploader } from "./CSVUploader";

export const uploadFileHelpMap: {
  [key in IUploadedVendorDataSourceModel]: {
    csvPDFGuideURL: string;
    csvTemplateURL: string;
  };
} = {
  subscriptions: {
    csvPDFGuideURL: "/static/assets/help/revenue-stream-schema.pdf",
    csvTemplateURL: "/static/assets/help/sample-revenue-streams.csv",
  },
};

export interface ICSVUploadManagerProps {
  label?: string;
  vendorID?: string;
  isLabelEditable?: boolean;
  isAdminRequest?: boolean;
  source: IConnectDataSourceFlowSource;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const CSVUploadExplainer: React.FC<ICSVUploadManagerProps> = (props) => {
  const { addAndOpenStepperDialog, removeTopOfStack, clearStepperStack } = useStepper();
  const { trackEvent } = useAnalytics();

  const { source } = props;

  const trackEducationModalEvent = useDataSourceUploadExplainerTracking(source);

  const uploadFileHelp = uploadFileHelpMap.subscriptions;

  const goBackAndTrack = () => {
    trackEducationModalEvent(CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_BACK_BUTTON_CLICKED_EVENT);
    removeTopOfStack();
  };

  return (
    <DataSourceInformationLayout
      heading="Uploading your revenue streams"
      description={
        <div>
          Before uploading your revenue streams, you'll first need to format them according to our
          standardized{" "}
          <a
            onClick={() =>
              trackEducationModalEvent(
                CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_TEMPLATE_LINK_CLICKED_EVENT,
              )
            }
            href={uploadFileHelp.csvTemplateURL}
            target="_blank"
          >
            CSV template
          </a>
          .
          <br />
          <br />
          Before continuing, check out our{" "}
          <a
            onClick={() =>
              trackEducationModalEvent(
                CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_DOCS_LINK_CLICKED_EVENT,
              )
            }
            href={uploadFileHelp.csvPDFGuideURL}
            target="_blank"
          >
            documentation
          </a>{" "}
          to learn more.
        </div>
      }
      imageSrc={Upload}
      primaryButtonText="Continue"
      primaryButtonAction={() => {
        trackEducationModalEvent(
          CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_CONTINUE_BUTTON_CLICKED_EVENT,
        );
        addAndOpenStepperDialog({
          config: {
            title: "Sync Your Revenue Streams",
            onCloseOverride: () => {
              const closeOutEvent =
                CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_CLOSED_OUT_EVENT[source];
              if (closeOutEvent != null) {
                trackEvent(closeOutEvent);
              }

              clearStepperStack();
            },
          },
          component: <CSVUploader {...props} />,
        });
      }}
      secondaryButtonText="Back"
      secondaryButtonAction={goBackAndTrack}
    />
  );
};
