import { useCallback, useMemo } from "react";

import { VendorNotInitializedError } from "~src/shared/auth/errors";
import { FetchVendorDataSourcesError } from "~src/shared/dataSources/errors";
import {
  IUseVendorDataSources,
  useVendorDataSources,
} from "~src/shared/dataSources/useVendorDataSources";
import { IRequest } from "~src/shared/errors/utils";

type ICategory = "bank" | "revenue" | "accounting";

export type IRequirement =
  | { type: "initial"; category: ICategory }
  | { type: "additional"; category: ICategory }
  | { type: "relink"; category: ICategory; sourceName: string; publicID: string };

type IErr = VendorNotInitializedError | FetchVendorDataSourcesError;

export const useFetchDataSourceRequirements = (): IRequest<IRequirement[], IErr> => {
  const { data, loading, refetch, error } = useVendorDataSources();

  const doRefetch = useCallback(async () => {
    await refetch();
  }, [refetch]);

  // Compute the requirements here if we are not loading or errored.
  const requirements = useMemo(() => {
    if (loading || error !== undefined) {
      return undefined;
    }
    return computeDataSourceRequirements(data);
  }, [loading, error, data]);

  // Wrap errors in the errors we want to return.
  const wrappedError = useMemo(() => {
    if (error !== undefined) {
      return new FetchVendorDataSourcesError("Error fetching data sources", { cause: error });
    }

    return undefined;
  }, [error]);

  return {
    data: requirements,
    loading,
    refetch: doRefetch,
    error: wrappedError,
  };
};

// computeDataSourceRequirements computes the linking status of the data source
// requirements.
const computeDataSourceRequirements = (vendor: IUseVendorDataSources["data"]): IRequirement[] => {
  const requirements: IRequirement[] = [];

  const accountingSources = vendor.dataSources.filter((s) => s.isAccounting);
  if (!vendor.isActivated && !vendor.hasUploadedAccountingCSV && accountingSources.length === 0) {
    requirements.push({ type: "initial", category: "accounting" });
  } else if (vendor.isAdditionalAccountingLinkRequired) {
    requirements.push({ type: "additional", category: "accounting" });
  } else {
    accountingSources
      .filter((s) => s.userActionRequiredAt !== undefined)
      .forEach((s) => {
        requirements.push({
          type: "relink",
          category: "accounting",
          sourceName: s.displayName,
          publicID: s.publicID,
        });
      });
  }

  const bankSources = vendor.dataSources.filter((s) => s.isBank);
  if (!vendor.isActivated && bankSources.length === 0) {
    requirements.push({ type: "initial", category: "bank" });
  } else if (vendor.isAdditionalBankLinkRequired) {
    requirements.push({ type: "additional", category: "bank" });
  } else {
    bankSources
      .filter(
        (s) =>
          s.userActionRequiredAt !== undefined &&
          !requirements.some((r) => r.type === "relink" && r.publicID === s.publicID),
      )
      .forEach((s) => {
        requirements.push({
          type: "relink",
          category: "bank",
          sourceName: s.displayName,
          publicID: s.publicID,
        });
      });
  }

  const revenueSources = vendor.dataSources.filter((s) => s.isRevenue);
  if (!vendor.isActivated && !vendor.hasUploadedBillingManagerCSV && revenueSources.length === 0) {
    requirements.push({ type: "initial", category: "revenue" });
  } else if (vendor.isAdditionalBillingManagerRequired) {
    requirements.push({ type: "additional", category: "revenue" });
  } else {
    revenueSources
      .filter(
        (s) =>
          s.userActionRequiredAt !== undefined &&
          !requirements.some((r) => r.type === "relink" && r.publicID === s.publicID),
      )
      .forEach((s) => {
        requirements.push({
          type: "relink",
          category: "revenue",
          sourceName: s.displayName,
          publicID: s.publicID,
        });
      });
  }

  return requirements;
};
