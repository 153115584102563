import styled from "@emotion/styled";
import React from "react";

import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";
import { Flexbox } from "~src/designSystem/layout/Flexbox";

export const CaughtUp: React.FC = () => {
  return (
    <Flexbox alignItems="center" paddingY="32px" gap="8px">
      <IconWrapper>
        <BlueprintIcon icon="tick" />
      </IconWrapper>
      <TextWrapper>You're all caught up!</TextWrapper>
    </Flexbox>
  );
};

const IconWrapper = styled.div`
  color: ${(props) => props.theme.components.Notifications.CaughtUp.icon};
`;

const TextWrapper = styled.div`
  color: ${(props) => props.theme.components.Notifications.CaughtUp.text};
`;
