import { useTheme } from "@emotion/react";
import React from "react";

import { ListBoxItem } from "~src/designSystem/atoms/ListBoxItem";
import { IImageOrSVG, ImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import { DataSourceHeadingDescriptionPairing } from "~src/shared/dataSources/components/DataSourceHeadingDescriptionPairing";
import { DataSourceModalWrapper } from "~src/shared/dataSources/components/DataSourceModalWrapper";
import { IIntegration } from "~src/shared/dataSources/useDataSourcesList";

import {
  DataSourceLayoutButtonSection,
  IDataSourceLayoutButtonSectionProps,
} from "./DataSourceLayoutButtonSection";

export interface IDataSourceLayoutProps extends IDataSourceLayoutButtonSectionProps {
  children?: React.ReactNode;
  dataSourceInfo?: IIntegration;
  description: string | React.ReactNode;
  heading: string;
  imageSrc?: IImageOrSVG;
}

export const DataSourceLayout: React.FC<IDataSourceLayoutProps> = (props) => {
  const { children, dataSourceInfo, description, heading, imageSrc } = props;
  const theme = useTheme();

  return (
    <DataSourceModalWrapper gap={theme.spacing.md}>
      {dataSourceInfo != null && (
        <ListBoxItem
          icon={dataSourceInfo.logo}
          label={dataSourceInfo.name}
          description={dataSourceInfo.subtitle}
        />
      )}
      <DataSourceHeadingDescriptionPairing heading={heading} description={description} />
      {imageSrc != null && <ImageOrSVG width={585} height={250} src={imageSrc} />}
      {children}
      <DataSourceLayoutButtonSection {...props} />
    </DataSourceModalWrapper>
  );
};
