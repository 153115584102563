import { ConsoleLog } from "~src/shared/helpers";
import { ISegmentTrackEvent, ISegmentTrackPage } from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource, IIntegrationType } from "~src/shared/types";

type IDataSourceSearchModalTrackPageMapType = {
  [key in IConnectDataSourceFlowSource]:
    | { [key1 in Omit<IIntegrationType, "bank"> as string]: ISegmentTrackPage | null }
    | null;
};

const DATA_SOURCE_SEARCH_MODAL_TRACK_PAGE_MAP: IDataSourceSearchModalTrackPageMapType = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow": {
    billing_manager: ISegmentTrackPage.SuggestionBillingManagerModalImpression,
    accounting: ISegmentTrackPage.SuggestionAddAccountingDataModalImpression,
  },
  "settings-flow": {
    billing_manager: ISegmentTrackPage.SettingsBillingManagerModalImpression,
    accounting: ISegmentTrackPage.SettingsAddAccountingDataModalImpression,
  },
  "cash-performance-flow": null,
  "trade-required-action-flow": {
    billing_manager: ISegmentTrackPage.TradeRequiredActionBillingManagerModalImpression,
    accounting: ISegmentTrackPage.TradeRequiredActionAddAccountingDataModalImpression,
  },
};

type IDataSourceSearchModalTrackEventMapType = {
  [key in IConnectDataSourceFlowSource]:
    | {
        [key1 in Omit<IIntegrationType, "bank"> as string]: ISegmentTrackEvent | null;
      }
    | null;
};

const DATA_SOURCE_SEARCH_MODAL_TRACK_EVENT_MAP: IDataSourceSearchModalTrackEventMapType = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow": {
    billing_manager: ISegmentTrackEvent.SuggestionBillingManagerInModalClick,
    accounting: ISegmentTrackEvent.SuggestionAddAccountingDataModalAccountingDataSourceClick,
  },
  "settings-flow": {
    billing_manager: ISegmentTrackEvent.SettingsBillingManagerInModalClick,
    accounting: ISegmentTrackEvent.SettingsAddAccountingDataModalDataSourceClick,
  },
  "cash-performance-flow": null,
  "trade-required-action-flow": {
    billing_manager: ISegmentTrackEvent.TradeRequiredActionBillingManagerInModalClick,
    accounting:
      ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalAccountingDataSourceClick,
  },
};

export const getDataSourcesSearchModalAnalyticsEvent = (
  type: Omit<IIntegrationType, "bank">,
  source?: IConnectDataSourceFlowSource,
): ISegmentTrackPage | null => {
  if (source === undefined) {
    return null;
  }

  const trackPage = DATA_SOURCE_SEARCH_MODAL_TRACK_PAGE_MAP[source]?.[type as string];

  if (trackPage != null) {
    return trackPage;
  }

  ConsoleLog(`Unexpected type=${type} or source=${source}`);
  return null;
};

export const getDataSourcesSearchModalListOptionEvent = (
  type: Omit<IIntegrationType, "bank">,
  source?: IConnectDataSourceFlowSource,
): ISegmentTrackEvent | null => {
  if (source === undefined) {
    return null;
  }

  const trackEvent = DATA_SOURCE_SEARCH_MODAL_TRACK_EVENT_MAP[source]?.[type as string];

  if (trackEvent != null) {
    return trackEvent;
  }

  ConsoleLog(`Unexpected type=${type} or source=${source}`);
  return null;
};

export const CONNECT_PLAID_SOURCE_TO_PLAID_MODAL_PAGE_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackPage | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  // Plaid modal is not part of the suggestions flow but if it
  // is in the future, we'd add the event for it here.
  "suggestion-flow": null,
  "settings-flow": ISegmentTrackPage.SettingsPlaidModalImpression,
  "cash-performance-flow": ISegmentTrackPage.CashPerformancePlaidModalImpression,
  "trade-required-action-flow": ISegmentTrackPage.TradeRequiredActionPlaidModalImpression,
  "trade-orderbox-add-payout-flow": null,
};

export const CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_PAGE_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackPage | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow": ISegmentTrackPage.SuggestionBillingManagerDetailsModalImpression,
  "settings-flow": ISegmentTrackPage.SettingsBillingManagerDetailsModalImpression,
  // Billing modal is not part of the cash-performance flow but if it
  // is in the future, we'd add the event for it here.
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackPage.TradeRequiredActionBillingManagerDetailsModalImpression,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_PAGE_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackPage | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow":
    ISegmentTrackPage.SuggestionAddAccountingDataModalAccountingDetailsModalImpression,
  "settings-flow": ISegmentTrackPage.SettingsAddAccountingDataModalAccountingDetailsModalImpression,
  // Accounting modal flow is not part of the cash-performance flow but if it becomes in
  // future, we'd add the event for it here.
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackPage.TradeRequiredActionAddAccountingDataModalAccountingDetailsModalImpression,
};

export const CONNECT_BILLING_SOURCE_TO_CONNECTOR_GO_BACK_BUTTON_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow": ISegmentTrackEvent.SuggestionBillingManagerDetailsModalBackButtonClick,
  "settings-flow": ISegmentTrackEvent.SettingsBillingManagerDetailsModalBackButtonClick,
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerDetailsModalBackButtonClick,
};

export const CONNECT_BILLING_SOURCE_TO_CONNECTOR_INSTRUCTIONS_BUTTON_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow": ISegmentTrackEvent.SuggestionBillingManagerDetailsModalInstructionsButtonClick,
  "settings-flow": ISegmentTrackEvent.SettingsBillingManagerDetailsModalInstructionsButtonClick,
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerDetailsModalInstructionsButtonClick,
};

export const CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_CLOSE: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow": ISegmentTrackEvent.SuggestionBillingManagerDetailsModalClose,
  "settings-flow": ISegmentTrackEvent.SettingsBillingManagerDetailsModalClose,
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerDetailsModalClose,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_GO_BACK_BUTTON_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionAddAccountingDataModalAccountingDetailsBackButtonClick,
  "settings-flow":
    ISegmentTrackEvent.SettingsAddAccountingDataModalAccountingDetailsModalBackButtonClick,
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalAccountingDetailsBackButtonClick,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_INSTRUCTIONS_BUTTON_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionAddAccountingDataModalAccountingDetailsInstructionsButtonClick,
  "settings-flow":
    ISegmentTrackEvent.SettingsAddAccountingDataModalAccountingDetailsModalInstructionsButtonClick,
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalAccountingDetailsInstructionsButtonClick,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_CLOSE_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "suggestion-flow": ISegmentTrackEvent.SuggestionAddAccountingDataModalAccountingDetailsModalClose,
  "settings-flow": ISegmentTrackEvent.SettingsAddAccountingDataModalAccountingDetailsModalClose,
  "cash-performance-flow": null,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalAccountingDetailsModalClose,
};
