import styled from "@emotion/styled";
import React from "react";

import { Avatar } from "~src/designSystem/atoms/Avatar";
import { textToColor } from "~src/shared/helpers";

type IProps = {
  name: string;
  size?: string;
};

/** @deprecated need to merge to use CompanyLogo */
export const UserAvatar: React.FC<IProps> = ({ name, size = "24px" }) => {
  return (
    <Wrapper backgroundColor={textToColor(name)} sizing={size}>
      {makeInitials(name)}
    </Wrapper>
  );
};

const makeInitials = (name: string): string => {
  // Get all non-empty words
  const words = name.split(" ").filter((w) => w.length > 0);

  if (words.length < 2) {
    return name[0] ?? "";
  }

  return `${words[0]?.charAt(0)}${words[words.length - 1]?.charAt(0)}`;
};

type IWrapperProps = {
  backgroundColor: string;
  sizing: string;
};

const Wrapper = styled(Avatar)<IWrapperProps>`
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.sizing};
  height: ${(props) => props.sizing};
  vertical-align: middle;
  border-radius: 100%;
  border: 0;
  object-fit: contain;
`;
