import Fuse from "fuse.js";
import { useCallback, useMemo } from "react";

import { IImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import {
  ACCOUNTING_PLATFORMS,
  CSV_ACCOUNTING_MANAGER,
} from "~src/shared/dataSources/accounting/accountingPlatforms";
import {
  BILLING_MANAGERS,
  CSV_BILLING_MANAGER,
} from "~src/shared/dataSources/billing/billingManagers";
import { IIntegrationType } from "~src/shared/types";

export type IIntegration<I extends IIntegrationType = IIntegrationType> = Readonly<{
  type: I;
  key: string;
  logo?: IImageOrSVG;
  name: string;
  subtitle: string;
}>;

type IDataSourcesList = Readonly<{
  search: (query: string) => Promise<readonly IIntegration[] | null>;
  dataSources: readonly IIntegration[];
}>;

type IUseDataSourcesList = Readonly<{
  type: IIntegrationType;
}>;

/**
 * Gets a list of all third-party data sources of a given type.
 */
export const useDataSourcesList = ({ type }: IUseDataSourcesList): IDataSourcesList => {
  const dataSources = {
    billing_manager: Object.values(BILLING_MANAGERS),
    accounting: Object.values(ACCOUNTING_PLATFORMS).filter(
      (p) => p.name !== "Xero" && p.name !== "Wave",
    ),
    bank: [],
  }[type] as readonly IIntegration[];
  const fuse = useMemo(() => {
    return new Fuse(dataSources, {
      includeScore: true,
      threshold: 0.3,
      keys: ["name", "website"],
    });
  }, [dataSources]);

  const search = useCallback(
    async (query: string) => {
      if (query === "") {
        return dataSources;
      }
      const d = fuse.search(query);
      const resultItems = d.map(({ item }) => item);

      // Display the CSV item when the user starts searching
      if (resultItems.length > 0) {
        const csvResultItem = {
          billing_manager: CSV_BILLING_MANAGER,
          accounting: CSV_ACCOUNTING_MANAGER,
          bank: null,
        }[type] as IIntegration | null;

        if (csvResultItem != null) {
          resultItems.push(csvResultItem);
        }
      }

      return resultItems;
    },
    [fuse, dataSources, type],
  );

  return { search, dataSources };
};
