/* eslint-disable react/style-prop-object */
import React from "react";

export const Success: React.FC = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="41.6" fill="#547BC9" />
    <path d="M33.8 52L46.8 65L72.8 36.4" stroke="white" strokeWidth="6" strokeLinejoin="round" />
  </svg>
);
