import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { Tooltip } from "~src/designSystem/atoms/Tooltip";

type IInnerProps = React.ComponentProps<typeof NavItemInner>;

type IProps = Omit<IInnerProps, "children"> & {
  title?: string;
  shortcut?: string;
  icon?: React.ReactNode;
};

export const NavItemWrapper: React.FC<IProps> = (props) => {
  const { shortcut, title, icon, ...innerProps } = props;

  const inner = (
    <Wrapper>
      <NavItemInner {...innerProps}>{icon ?? title}</NavItemInner>
    </Wrapper>
  );

  const tooltip = [];
  if (icon !== undefined && title !== undefined) {
    tooltip.push(title);
  }
  if (shortcut !== undefined) {
    tooltip.push(<Shortcut>⌘ {shortcut}</Shortcut>);
  }

  if (tooltip.length === 0) {
    return inner;
  }

  return (
    <Tooltip placement="bottom" tooltipContent={<span>{tooltip}</span>}>
      {inner}
    </Tooltip>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;

const NavItemInner = styled.div<{
  isActive?: boolean;
}>`
  transition: ${(props) => props.theme.transition};
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
  height: 32px;

  cursor: pointer;
  color: ${({ theme }) => theme.components.NavBar.Texticon.inactive};

  & > svg {
    width: 16px;
    height: 16px;
  }

  border-radius: 2px;
  ${{
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "16px",
  }};
  font-weight: 600;

  ${({ theme, isActive }) => {
    return (
      isActive === true &&
      css`
        background-color: ${theme.components.NavBar.Texticon.background.default};
        color: ${theme.components.NavBar.Texticon.active};
      `
    );
  }}

  &:hover {
    background-color: ${(props) => props.theme.components.NavBar.Texticon.background.hover};
    color: ${({ theme }) => theme.components.NavBar.Texticon.active};
  }
`;

const Shortcut = styled.span`
  border-radius: 2px;
  background-color: "#515151";
  padding: 0 2px;
  margin-left: 4px;
`;
