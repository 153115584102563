/* eslint-disable simple-import-sort/imports */
import Lottie from "react-lottie-wrapper";
import React from "react";

import pulseBlue from "./pulse-blue.json";
import pulseGreen from "./pulse-green.json";
import pulseOrange from "./pulse-orange.json";
import pulseRed from "./pulse-red.json";

import { Flexbox } from "~src/designSystem/layout/Flexbox";

export type IColor = "green" | "orange" | "red" | "blue";

type IAnimation = unknown;

interface IProps {
  color?: IColor;
  /** disables the pulsing */
  noPulse?: boolean;
}

const animations: { [key in IColor]: IAnimation } = {
  green: pulseGreen,
  orange: pulseOrange,
  red: pulseRed,
  blue: pulseBlue,
};

export const IndicatorIcon: React.FC<IProps> = (props: IProps) => {
  const { color = "orange", noPulse = false } = props;

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[color],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Flexbox width="fit-content" height="fit-content">
      <Lottie
        key={color}
        options={animationOptions}
        height={20}
        width={20}
        isStopped={noPulse}
        isPaused={noPulse}
      />
    </Flexbox>
  );
};
