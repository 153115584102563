import styled from "@emotion/styled";
import React from "react";

import { useSrcValidator } from "~src/shared/helpers/hooks";

export type IImageOrSVG = string | React.FC<React.SVGProps<SVGSVGElement>>;

interface IProps {
  src?: IImageOrSVG;
  width?: number;
  height?: number;
  fallbackImage?: React.ReactNode;
  /**
   * @default true
   */
  rounded?: boolean;
}

export const ImageOrSVG: React.FC<IProps> = ({
  src: Logo,
  fallbackImage,
  width = 34,
  height = 34,
  rounded = true,
}) => {
  const isImageValid = useSrcValidator(typeof Logo === "string" ? Logo : undefined);
  return (
    <Wrapper width={width} height={height} rounded={rounded}>
      {Logo !== undefined && (typeof Logo === "string" ? isImageValid : true) ? (
        typeof Logo === "string" ? (
          <img src={Logo} />
        ) : (
          <Logo />
        )
      ) : (
        fallbackImage ?? <span>❓</span>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  width: number;
  height: number;
  rounded: boolean;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  & > img,
  & > svg {
    width: 100%;
    height: 100%;
    ${(props) => props.rounded && "border-radius: 100%"};
  }

  display: flex;
  align-items: center;
  & > span {
    vertical-align: middle;
    line-height: 2;
    font-size: ${(props) => (props.height * 4) / 5}px;
  }
`;
