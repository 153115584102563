import styled from "@emotion/styled";

import { OldInputGoUseTextfield } from "../OldInput";

export type LabeledInputProps = Pick<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "value" | "onBlur" | "placeholder" | "defaultValue" | "onChange" | "disabled" | "className"
> & {
  label: string;
  name: string;
  type?: "email" | "text" | "password" | "hidden" | "number" | "search" | "tel" | "url";
  error?: string;
};

const _LabeledInput = (props: LabeledInputProps) => {
  const { className, label, name, ...extraProps } = props;
  return (
    <div className={className}>
      <label htmlFor={name}>
        {label} <span style={{ color: "red" }}>{extraProps.error}</span>
      </label>
      <OldInputGoUseTextfield id={name} name={name} autoComplete="off" {...extraProps} />
    </div>
  );
};

export const OldLabeledInputDontUse = styled(_LabeledInput)`
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.components.Input.label.default};
  }
`;
