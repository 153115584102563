import React from "react";

import { IIntegrationType, IUploadedVendorDataType } from "~src/shared/types";

export const DATA_SOURCE_TEXTS: {
  [key in IIntegrationType]: Readonly<{
    title: string;
    name: string;
    search: string;
    description: string | React.ReactNode;
  }>;
} = {
  accounting: {
    title: "Accounting",
    name: "accounting data",
    search: "accounting platform",
    description:
      "Connecting your accounting data allows us to verify your financials. At Pipe, we never share your data with outside third parties.",
  },
  billing_manager: {
    title: "Billing",
    name: "revenue streams",
    search: "billing manager",
    description:
      "Connecting allows us to automatically identify your revenue streams and sync them to your Pipe dashboard. At Pipe, we never share your data with outside third parties.",
  },
  bank: {
    title: "Banking",
    name: "banking data",
    search: "your bank",
    description: (
      <>
        <>
          We’ve partnered with Plaid to enable secure, seamless connection with hundreds of
          financial institutions.
        </>
        <br />
        <br />
        <>
          Below, select the country you bank with to connect via Plaid. If you have multiple bank
          accounts, accept more than one currency, or bank in multiple countries, you can add them
          one by one.
        </>
      </>
    ),
  },
};

export const INTEGRATION_TYPE_TO_UPLOADED_VENDOR_DATA_TYPE_MAP: {
  [key in IIntegrationType]: IUploadedVendorDataType;
} = {
  accounting: "accounting",
  bank: "bank",
  billing_manager: "billing",
};
