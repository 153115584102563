import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { Header, TOPBAR_HEIGHT } from "./Header";
import { ISubnav, Subnav } from "./Subnav";

const SIDEBAR_WIDTH = 237;

type IProps = React.ComponentProps<typeof Header> & {
  /**
   * Subnavigation
   */
  subnav?: ISubnav;
  children?: React.ReactNode;

  maxContentWidth?: string;
  noPadding?: boolean;
  noOverflowY?: boolean;
};

/**
 * Main application layout area.
 */
export const V2Main: React.FC<IProps> = ({
  subnav,
  children,

  noPadding,
  noOverflowY,
  maxContentWidth,

  // Header props.
  brandLinkHref,
  navItems,
  onSearchClick,
}: IProps) => (
  <BigWrapper>
    <Header brandLinkHref={brandLinkHref} navItems={navItems} onSearchClick={onSearchClick} />
    {subnav !== undefined ? (
      <MainArea>
        {" "}
        <Subnav {...subnav} />
        <ContentWrapper
          maxContentWidth={maxContentWidth}
          noPadding={noPadding}
          noOverflowY={noOverflowY}
        >
          {children}
        </ContentWrapper>
      </MainArea>
    ) : (
      <ContentWrapper
        maxContentWidth={maxContentWidth}
        noPadding={noPadding}
        noOverflowY={noOverflowY}
      >
        {children}
      </ContentWrapper>
    )}
  </BigWrapper>
);

const ContentWrapper = styled.div<{
  maxContentWidth?: string;
  noPadding?: boolean;
  noOverflowY?: boolean;
}>`
  overflow: auto;
  ${({ noOverflowY }) =>
    noOverflowY === true &&
    css`
      overflow-y: hidden;
    `};

  ${({ noPadding }) =>
    noPadding !== true &&
    css`
      padding: 48px;
      padding-bottom: 0;
    `}

  ${({ maxContentWidth }) =>
    maxContentWidth !== undefined &&
    css`
      max-width: ${maxContentWidth};
    `};
`;

const MainArea = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: ${SIDEBAR_WIDTH}px calc(100vw - ${SIDEBAR_WIDTH}px);
`;

const BigWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: ${TOPBAR_HEIGHT}px calc(100vh - ${TOPBAR_HEIGHT}px);
  overflow-y: auto;
`;
