import styled from "@emotion/styled";
import React, { ReactNode } from "react";

interface IProps {
  size?: string;
  margin?: string;
  textAlign?: string;
  color?: string;
  lineHeight?: string;
  maxWidth?: string;
  width?: string;
  content?: string; // use content or children, not both
  children?: ReactNode;
  weight?: string;
  style?: React.CSSProperties;
}

/**
 * @deprecated use {@link ReallyOldParagraphDontUse}
 */
export const ReallyOldParagraphDontUse: React.FC<IProps> = (props: IProps) => {
  const { content, children } = props;
  if (children === undefined && content === undefined) {
    return null;
  }
  return (
    <StyledP {...props}>
      {children}
      {content}
    </StyledP>
  );
};

const StyledP = styled("p")<IProps>`
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  margin: ${(props) => props.margin};

  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight ?? "400"};

  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeight};
`;
