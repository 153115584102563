/* eslint-disable react/style-prop-object */
import React from "react";

export const Xero: React.FC = () => (
  <svg
    height="786.81995"
    id="svg4443"
    width="786.81995"
    version="1.1"
    viewBox="0 0 208.17944 208.17944"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs4437">
      <clipPath id="clipPath18">
        <path id="path16" d="M 0,850.394 H 850.394 V 0 H 0 Z" />
      </clipPath>
    </defs>
    <g id="layer1" transform="translate(-5.8874759,-271.69643)">
      <g id="g10" transform="matrix(0.35277777,0,0,-0.35277777,-39.929542,525.63751)">
        <g id="g12">
          <g id="g14" clipPath="url(#clipPath18)">
            <path
              id="path20"
              style={{ fill: "#1ab4d7", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 424.933,130.843 c 162.335,0 293.932,131.597 293.932,293.933 0,162.334 -131.597,293.932 -293.932,293.932 C 262.598,718.708 131,587.11 131,424.776 131,262.44 262.598,130.843 424.933,130.843"
            />
            <path
              id="path22"
              style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 271.701,425.625 50.162,50.286 c 1.663,1.695 2.591,3.939 2.591,6.319 0,4.96 -4.032,8.988 -8.992,8.988 -2.416,0 -4.681,-0.947 -6.389,-2.676 -0.005,-0.01 -50.117,-50.093 -50.117,-50.093 l -50.341,50.168 c -1.701,1.677 -3.955,2.601 -6.345,2.601 -4.954,0 -8.987,-4.026 -8.987,-8.981 0,-2.417 0.965,-4.685 2.697,-6.394 l 50.155,-50.148 -50.129,-50.223 c -1.762,-1.735 -2.723,-4.008 -2.723,-6.436 0,-4.959 4.033,-8.982 8.987,-8.982 2.394,0 4.65,0.929 6.345,2.625 l 50.263,50.195 50.072,-50.012 c 1.776,-1.836 4.067,-2.814 6.512,-2.814 4.955,0 8.987,4.029 8.987,8.988 0,2.393 -0.927,4.641 -2.614,6.336 z"
            />
            <path
              id="path24"
              style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 569.906,425.64 c 0,-9.006 7.323,-16.332 16.34,-16.332 8.996,0 16.322,7.326 16.322,16.332 0,9.008 -7.326,16.333 -16.322,16.333 -9.017,0 -16.34,-7.325 -16.34,-16.333"
            />
            <path
              id="path26"
              style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 538.93,425.632 c 0,26.08 21.216,47.3 47.299,47.3 26.07,0 47.291,-21.22 47.291,-47.3 0,-26.074 -21.221,-47.285 -47.291,-47.285 -26.083,0 -47.299,21.211 -47.299,47.285 m -18.606,0 c 0,-36.334 29.565,-65.895 65.905,-65.895 36.339,0 65.919,29.561 65.919,65.895 0,36.339 -29.58,65.908 -65.919,65.908 -36.34,0 -65.905,-29.569 -65.905,-65.908"
            />
            <path
              id="path28"
              style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 515.646,490.413 -2.765,0.008 c -8.301,0 -16.307,-2.618 -22.994,-7.771 -0.881,4.032 -4.485,7.066 -8.778,7.066 -4.941,0 -8.9,-3.959 -8.912,-8.91 0,-0.018 0.03,-111 0.03,-111 0.013,-4.942 4.044,-8.959 8.988,-8.959 4.943,0 8.973,4.017 8.986,8.97 0,0.022 0.004,68.251 0.004,68.251 0,22.748 2.08,31.935 21.566,34.37 1.801,0.224 3.76,0.189 3.768,0.189 5.332,0.183 9.121,3.847 9.121,8.799 0,4.955 -4.045,8.987 -9.014,8.987"
            />
            <path
              id="path30"
              style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 343.072,436.482 c 0,0.246 0.019,0.502 0.031,0.755 5.218,20.627 23.899,35.891 46.147,35.891 22.517,0 41.369,-15.639 46.32,-36.646 z m 110.903,1.697 c -3.873,18.337 -13.912,33.399 -29.199,43.072 -22.346,14.187 -51.854,13.402 -73.438,-1.947 -17.606,-12.525 -27.769,-33.014 -27.769,-54.12 0,-5.292 0.638,-10.634 1.966,-15.892 6.648,-26.152 29.13,-45.949 55.934,-49.23 7.954,-0.963 15.696,-0.502 23.713,1.574 6.889,1.676 13.555,4.47 19.697,8.406 6.375,4.098 11.701,9.502 16.859,15.971 0.104,0.119 0.209,0.224 0.313,0.349 3.58,4.44 2.916,10.754 -1.018,13.768 -3.318,2.543 -8.888,3.574 -13.271,-2.039 -0.942,-1.342 -1.993,-2.723 -3.15,-4.104 -3.485,-3.851 -7.809,-7.582 -12.99,-10.476 -6.593,-3.522 -14.106,-5.535 -22.093,-5.58 -26.142,0.291 -40.131,18.539 -45.107,31.564 -0.869,2.432 -1.539,4.955 -2.006,7.553 -0.061,0.486 -0.106,0.955 -0.124,1.387 5.405,0 93.812,0.015 93.812,0.015 12.861,0.27 19.783,9.348 17.871,19.729"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
