/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FormikProps, FormikValues } from "formik";

import { OldLabeledInputDontUse } from "~src/designSystem/deprecated/LabeledInput";

interface ICredentialFieldProps<Values extends FormikValues> {
  fieldKey: Extract<keyof Values, string>;
  form: FormikProps<Values>;
  label: string;
  type: "text" | "password";
}

export const CredentialField = <Values extends FormikValues>(
  props: ICredentialFieldProps<Values>,
): React.ReactElement => {
  const { fieldKey, label, type, form } = props;
  const { values, handleChange, handleBlur } = form;
  const value = values[fieldKey];
  const formError = form.errors[fieldKey];
  const error =
    form.touched[fieldKey] === true && typeof formError === "string" ? formError : undefined;
  return (
    <OldLabeledInputDontUse
      data-private
      error={error}
      name={fieldKey}
      type={type}
      label={label}
      key={fieldKey}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

interface ISubdomainFieldProps<Values> extends Omit<ICredentialFieldProps<Values>, "type"> {
  domain: string;
}

export const SubdomainField = <Values extends FormikValues>(
  props: ISubdomainFieldProps<Values>,
): React.ReactElement => (
  <SubdomainInputContainer>
    <CredentialField type="text" {...props} />
    <span
      css={css`
        margin-left: 4px;
        margin-bottom: 12px;
        align-self: flex-end;
      `}
    >
      .{props.domain}
    </span>
  </SubdomainInputContainer>
);

const SubdomainInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  color: ${(props) => props.theme.components.DataSources.SubdomainInputContainer.Input.color};
`;
