import React from "react";

import { Link } from "~src/designSystem/atoms/Link";
import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import {
  PIPE_CONTACT_URL,
  SUPPORT_EMAIL,
  SUPPORT_EMAIL_TEMPLATE_HREF,
} from "~src/shared/constants";
import { getSupportPhoneNumber } from "~src/shared/helpers/support";
import { ICountryCode, IIntegrationType } from "~src/shared/types";

export type IProps = {
  integrationType?: IIntegrationType;
  country?: ICountryCode;
};

export const ContactSupport: React.FC<IProps> = (props) => {
  const { integrationType, country } = props;

  const supportNumber = getSupportPhoneNumber(country);

  const text = (() => {
    switch (integrationType) {
      case "bank": {
        return "Don't see your bank?";
      }
      case "accounting": {
        return "Don't see your accounting software?";
      }
      case "billing_manager":
      default: {
        return "Running into issues?";
      }
    }
  })();

  return (
    <OldSpanUseTextInstead>
      {text}
      <br />
      Call us at <Link href={`tel:${supportNumber}`}>{supportNumber}</Link>, send us an email at{" "}
      <Link newTab href={SUPPORT_EMAIL_TEMPLATE_HREF}>
        {SUPPORT_EMAIL}
      </Link>
      , or{" "}
      <Link newTab href={PIPE_CONTACT_URL}>
        submit a support request.
      </Link>
    </OldSpanUseTextInstead>
  );
};
