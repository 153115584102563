/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import React from "react";

import { Card } from "../Card";

export type ITwoPartCardProps = React.ComponentProps<typeof Card> & {
  children: [React.ReactNode, React.ReactNode];
};

export const TwoPartCard: React.FC<ITwoPartCardProps> = (props) => {
  const theme = useTheme();
  const [child1, child2] = props.children;

  return (
    <Card {...props} padding="0px">
      {child1}
      {/* The border. */}
      <div
        css={css`
          border: 1px solid ${theme.components.Card.Base.border};
        `}
      />
      {child2}
    </Card>
  );
};
