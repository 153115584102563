/* eslint-disable react/style-prop-object */
import React from "react";

export const CommandIcon: React.FC = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 6.75H9C10.245 6.75 11.25 7.755 11.25 9C11.25 10.245 10.245 11.25 9 11.25C7.755 11.25 6.75 10.245 6.75 9V8.25H5.25V9C5.25 10.245 4.245 11.25 3 11.25C1.755 11.25 0.75 10.245 0.75 9C0.75 7.755 1.755 6.75 3 6.75H3.75V5.25H3C1.755 5.25 0.75 4.245 0.75 3C0.75 1.755 1.755 0.75 3 0.75C4.245 0.75 5.25 1.755 5.25 3V3.75H6.75V3C6.75 1.755 7.755 0.75 9 0.75C10.245 0.75 11.25 1.755 11.25 3C11.25 4.245 10.245 5.25 9 5.25H8.25V6.75ZM9.75 3C9.75 2.5875 9.4125 2.25 9 2.25C8.5875 2.25 8.25 2.5875 8.25 3C8.25 3.4125 8.5875 3.75 9 3.75C9.4125 3.75 9.75 3.4125 9.75 3ZM3 9.75C2.5875 9.75 2.25 9.4125 2.25 9C2.25 8.5875 2.5875 8.25 3 8.25C3.4125 8.25 3.75 8.5875 3.75 9C3.75 9.4125 3.4125 9.75 3 9.75ZM2.25 3C2.25 3.4125 2.5875 3.75 3 3.75C3.4125 3.75 3.75 3.4125 3.75 3C3.75 2.5875 3.4125 2.25 3 2.25C2.5875 2.25 2.25 2.5875 2.25 3ZM6.75 6.75H5.25V5.25H6.75V6.75ZM8.25 9C8.25 9.4125 8.5875 9.75 9 9.75C9.4125 9.75 9.75 9.4125 9.75 9C9.75 8.5875 9.4125 8.25 9 8.25C8.5875 8.25 8.25 8.5875 8.25 9Z"
    />
  </svg>
);
