import { GraphQLError, IErrorOptions } from "~src/shared/errors/taxonomy";

export class FetchVendorDataSourcesError extends GraphQLError {
  constructor(message: string, options?: IErrorOptions) {
    super(message, {
      ...options,
      code: "DATA_SOURCES/FETCH_VENDOR_DATA_SOURCES",
      transient: true,
    });
  }
}
