import React, { useState } from "react";

import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { DataSourceLayout } from "~src/shared/dataSources/components/dataSourceLayout";
import { BASE_URL } from "~src/shared/env";
import { useEnv } from "~src/shared/env/useEnv";
import { useStepper } from "~src/shared/stepper/stepperContext";

import { BILLING_MANAGERS } from "../billingManagers";

interface IProps {
  redirectPath: IAllowedRedirectPath;
}

export const ConnectStripe: React.FC<IProps> = ({ redirectPath }) => {
  const env = useEnv();
  const { removeTopOfStack } = useStepper();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const redirectToStripe = () => {
    setIsRedirecting(true);
    const params = new URLSearchParams({
      scope: "read_only",
      // Match URI configured in https://dashboard.stripe.com/test/settings/applications.
      redirect_uri: BASE_URL + `/api/stripe-oauth-callback?redirect_path=${redirectPath}`,
      stripe_landing: "register",
      response_type: "code",
      client_id: env.STRIPE_OAUTH_CLIENT_ID,
    });
    window.location.href = `https://dashboard.stripe.com/oauth/authorize?${params.toString()}`;
  };

  return (
    <DataSourceLayout
      dataSourceInfo={BILLING_MANAGERS.stripe}
      heading="Authenticate with OAuth"
      description="Securely connect your Stripe account via OAuth. You’ll be directed to Stripe’s website to sign in. Once you’re done, you’ll be redirected back to Pipe."
      secondaryButtonText="Back"
      secondaryButtonAction={removeTopOfStack}
      primaryButtonText={isRedirecting ? "Transferring you to Stripe..." : "Continue to Stripe"}
      primaryButtonAction={redirectToStripe}
      isPrimaryButtonDisabled={isRedirecting}
    />
  );
};
