import styled from "@emotion/styled";
import React from "react";

import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";

export const ErrorComponent: React.FC = () => (
  <Wrapper>
    <ErrorIcon>
      <BlueprintIcon icon="error" />
    </ErrorIcon>
    <ErrorText>
      Uh oh, something's gone wrong!
      <br />
      Our engineers have been notified.
    </ErrorText>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 16px;
`;

const ErrorIcon = styled.div`
  line-height: 0;
  color: ${(props) => props.theme.components.Error.icon};
`;

const ErrorText = styled.div`
  ${(props) => props.theme.textStyles.Regular.Body200};
  color: ${(props) => props.theme.components.Error.text};
  text-align: center;
`;
