/* eslint-disable react/style-prop-object */
import React from "react";

export const Quickbooks: React.FC = () => (
  <svg
    width="107"
    height="107"
    viewBox="0 0 107 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.2754 106.148C82.4586 106.148 106.116 82.4456 106.116 53.207C106.116 23.9684 82.4586 0.265869 53.2754 0.265869C24.0921 0.265869 0.434448 23.9684 0.434448 53.207C0.434448 82.4456 24.0921 106.148 53.2754 106.148Z"
      fill="#2CA01C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1078 53.2047C15.1078 64.5753 24.308 73.7929 35.657 73.7929H38.5926V66.1459H35.657C28.5341 66.1459 22.7404 60.3412 22.7404 53.2047C22.7404 46.0694 28.5341 40.2635 35.657 40.2635H42.7107V80.2635C42.7107 84.487 46.1278 87.9106 50.3433 87.9106V32.6165H35.657C24.308 32.6165 15.1078 41.8353 15.1078 53.2047ZM70.896 32.62H67.9604V40.267H70.896C78.0166 40.267 83.8127 46.0729 83.8127 53.2082C83.8127 60.3435 78.0166 66.1494 70.896 66.1494H63.8388V26.1494C63.8388 21.9259 60.4218 18.5023 56.2063 18.5023V73.7965H70.896C82.2439 73.7965 91.4453 64.5788 91.4453 53.2082C91.4453 41.8376 82.2439 32.62 70.896 32.62Z"
      fill="white"
    />
  </svg>
);
