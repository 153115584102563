/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ChangeEventHandler } from "react";

import { OldFullWidthContainerDontUse } from "../FullWidthContainer";

type IProps = Pick<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "id" | "type" | "name" | "onBlur" | "step" | "value" | "placeholder" | "onFocus"
> & {
  label?: React.ReactNode;
  labelUppercase?: boolean;
  defaultValue?: string | number;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  error?: string;
  htmlOnChange?: ChangeEventHandler<HTMLInputElement>;
  inputHeight?: string;
  // TODO (ble): remove and make font-size inherit
  inputFontSize?: string;
};

/** @deprecated use {@link Input.new} */
export const ReallyOldInputDontUse: React.FC<IProps> = (props) => {
  const {
    children,
    disabled,
    error,
    htmlOnChange,
    label,
    labelUppercase = true,
    onChange,
    style,
    type,
    inputHeight,
    inputFontSize,
    ...inputProps
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = type === "number" ? Number(event.target.value) : event.target.value;
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <OldFullWidthContainerDontUse style={{ margin: "10px 0", minHeight: "fit-content", ...style }}>
      <StyledLabel error={error} labelUppercase={labelUppercase}>
        {label}
      </StyledLabel>
      <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
        <input
          aria-label="text-input"
          autoComplete="off"
          onChange={htmlOnChange || handleChange}
          type={type ?? "text"}
          disabled={disabled}
          css={fieldStyles({ error, disabled, inputHeight, inputFontSize })}
          {...inputProps}
        />
        {children}
      </div>
    </OldFullWidthContainerDontUse>
  );
};

const errorColor = "red";

interface ILabel {
  error?: string | null;
  labelUppercase?: boolean;
}

/** @deprecated */
export const StyledLabel = styled.label<ILabel>`
  font-size: 11px;
  color: #656565;

  height: "fit-content";

  margin-bottom: 4px;
  ${(props) => props.error !== undefined && `color: ${errorColor};`}
`;

interface IField {
  error?: string;
  disabled?: boolean;
  inputHeight?: string;
  inputFontSize?: string;
}

const fieldStyles = (props: IField) => {
  return css`
    height: ${props.inputHeight ?? "50px"};
    width: 100%;

    font-weight: 400;
    font-size: ${props.inputFontSize ?? "13px"};
    font-family: inherit;

    padding: 20px;
    outline: none;

    background-color: #fafcfd;
    border: none;
    border: 1px solid #e6eef2;
    border-radius: 2px;
    ${props.error !== undefined && `border-color: ${errorColor};`}
    ${props.disabled !== undefined && `background: #f2f2f2;`}
  `;
};
