/* eslint-disable react/style-prop-object */
import React from "react";

export const Netsuite: React.FC = () => (
  <svg viewBox="0 0.2 150 150" xmlns="http://www.w3.org/2000/svg" width="2500" height="2497">
    <path d="M0 .2h150v150H0z" fill="none" />
    <path
      d="M20.1 47.7h23.7V104h11.8v22H20.1zm109.7 51.8h-23.7V43.2H94.3v-22h35.5z"
      fill="#baccdb"
    />
    <path
      d="M14.6 15.8h74.9v64.3L60.7 43H14.6zm120.6 115.7H60.3V67.2l28.8 37.1h46.1"
      fill="#125580"
    />
  </svg>
);
