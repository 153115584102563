import React, { useCallback, useState } from "react";

import { IFile } from "~src/designSystem/atoms/Dropzone/useFilesUploading";
import { VendorDropzone } from "~src/designSystem/atoms/Dropzone/VendorDropzone";
import { Success } from "~src/designSystem/icons/Success";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { isNotUndefined } from "~src/shared/helpers/booleanCoercion";
import { authedRequests } from "~src/shared/requests/authedRequests";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { DataSourceInformationLayout } from "../../components/dataSourceInformationLayout";
import { DataSourceLayout } from "../../components/dataSourceLayout";
import {
  CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_BACK_BUTTON_CLICKED_EVENT,
  CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_FILE_UPLOADED_EVENT,
  CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_TEMPLATE_LINK_CLICKED_EVENT,
  CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_UPLOAD_BUTTON_CLICKED_EVENT,
  useAccountingCSVUploadTracking,
} from "../../tracking/useAccountingCSVUploadTracking";

interface IProps {
  source: IConnectDataSourceFlowSource;
  onSuccess?: () => void;
}

const sampleURL = "https://storage.googleapis.com/production-assets/sample-pl-balance-sheet.xlsx";

export const UploadAccountingCSV: React.FC<IProps> = (props: IProps) => {
  const { source, onSuccess } = props;
  const { addAndOpenStepperDialog, removeTopOfStack, clearStepperStack } = useStepper();

  const [files, setFiles] = useState<readonly IFile[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const trackUploaderEvent = useAccountingCSVUploadTracking(source);

  const onUploadSuccess = useCallback(() => {
    addAndOpenStepperDialog({
      component: (
        <DataSourceInformationLayout
          heading="Accounting data added"
          description="Nicely done! You've successfully added your financial statements."
          imageSrc={Success}
          primaryButtonText="Done"
          primaryButtonAction={clearStepperStack}
        />
      ),
    });
  }, [addAndOpenStepperDialog, clearStepperStack]);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsSubmitting(true);

    trackUploaderEvent(CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_UPLOAD_BUTTON_CLICKED_EVENT);

    e.preventDefault();
    e.stopPropagation();
    const { ok } = await callRequest(
      authedRequests.uploadIntegrationFiles({
        integration: "accounting",
        files: files.map((f) => f.fileName).filter(isNotUndefined),
      }),
    );
    if (ok) {
      await onSuccess?.();
      onUploadSuccess();
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <DataSourceLayout
      description={
        <p>
          Securely upload balance sheet and profit and loss reports to populate your Pipe dashboard.
          With Pipe, your identity and data are fully protected and anonymized.
          <br />
          <a
            onClick={() => {
              trackUploaderEvent(
                CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_TEMPLATE_LINK_CLICKED_EVENT,
              );
            }}
            href={sampleURL}
            target="_blank"
          >
            Download example CSV format.
          </a>
        </p>
      }
      heading="Upload your financial statements"
      primaryButtonText="Upload"
      primaryButtonAction={handleClick}
      isPrimaryButtonDisabled={files.length === 0 || isSubmitting}
      isPrimaryButtonLoading={isSubmitting}
      secondaryButtonText="Back"
      secondaryButtonAction={() => {
        trackUploaderEvent(
          CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_BACK_BUTTON_CLICKED_EVENT,
        );
        removeTopOfStack();
      }}
    >
      <Gridbox>
        <VendorDropzone
          directory="accounting"
          accepts={[
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          extensions={["csv", "xlxs", "xls"]}
          onFilesUpdate={(newFiles) => {
            trackUploaderEvent(CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_FILE_UPLOADED_EVENT);
            setFiles(newFiles);
          }}
        />
      </Gridbox>
    </DataSourceLayout>
  );
};
