import React, { useCallback } from "react";
import toast from "react-hot-toast";
import create from "zustand";

import { authedRequests } from "~src/shared/requests/authedRequests";
import { callRequest } from "~src/shared/requests/useRequest";

type IRestoreFromDemo = {
  hasAdminBackupSession: boolean;
  loading: boolean;
  restoreAdminBackupSession: () => Promise<void>;
};

export const IS_IN_DEMO = "isInDemo";

type IRestoreStore = {
  hasAdminBackupSession: boolean;
  setHasAdminBackupSession: (arg0: boolean) => void;
  loading: boolean;
  setLoading: (arg0: boolean) => void;
};

const useRestoreStore = create<IRestoreStore>((set) => ({
  hasAdminBackupSession: false,
  setHasAdminBackupSession: (hasAdminBackupSession) => set(() => ({ hasAdminBackupSession })),
  loading: true,
  setLoading: (loading) => set(() => ({ loading })),
}));

/**
 * useRestoreDemo exposes a set of functions for interacting with the demo session
 * restoration feature. This allows us to "restore" our admin session after a demo
 * without logging out & logging in.
 */
export const useRestoreFromDemo = (): IRestoreFromDemo => {
  const hasAdminBackupSession = useRestoreStore((s) => s.hasAdminBackupSession);
  const setHasAdminBackupSession = useRestoreStore((s) => s.setHasAdminBackupSession);
  const loading = useRestoreStore((s) => s.loading);
  const setLoading = useRestoreStore((s) => s.setLoading);

  React.useEffect(() => {
    // If we already loaded, don't run again.
    if (!loading) {
      return;
    }

    if (localStorage.getItem(IS_IN_DEMO) !== "1") {
      setLoading(false);
      return;
    }

    (async () => {
      const { data, ok } = await callRequest(authedRequests.checkBackupSession({}));
      if (ok && data?.valid === true) {
        setHasAdminBackupSession(true);
      }
      setLoading(false);
    })();
  }, [setHasAdminBackupSession, loading, setLoading]);

  const restoreAdminBackupSession = useCallback(async () => {
    const res = await callRequest(authedRequests.restoreBackupSession({}));
    if (!res.ok) {
      toast.error("Failed to restore admin session.");
      return;
    }
    localStorage.setItem(IS_IN_DEMO, "0");
    window.location.assign("/admin");
  }, []);

  return {
    hasAdminBackupSession,
    loading,
    restoreAdminBackupSession,
  };
};
