/* eslint-disable react/style-prop-object */
import React from "react";

export const Warning: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3L18 16H2L10 3ZM9 11V7H11V11H9ZM9 12.5V14.5H11V12.5H9Z"
      fill="#FFBE0A"
    />
  </svg>
);
