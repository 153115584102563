import React from "react";

import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { AddDataSource } from "../addDataSource";

interface IUseConnectDataSourceArgs {
  /** Invoked after a user successfully submits a credential form. */
  onSuccess: () => Promise<void>;
  /** Invoked after a user successfully completes an OAuth flow. */
  redirectPath: IAllowedRedirectPath;
  source: IConnectDataSourceFlowSource;
}

export const useConnectDataSource = ({
  onSuccess,
  redirectPath,
  source,
}: IUseConnectDataSourceArgs): (() => void) => {
  const { addAndOpenStepperDialog } = useStepper();

  return () =>
    addAndOpenStepperDialog({
      component: (
        <AddDataSource redirectPath={redirectPath} onSuccess={onSuccess} source={source} />
      ),
      config: {
        title: "Add data source",
      },
    });
};
