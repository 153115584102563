import { css, FunctionInterpolation, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { IGridStyles, withGridStyles } from "~src/shared/helpers/sharedComponentProps";

// TODO:(Benny) remove optional type
type IProps = IGridStyles & {
  disabled?: boolean;
  hideBorders?: boolean;
  borderColor?: string;
  error?: string;
};

export type IInputProps = IProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const withInputStyles: FunctionInterpolation<IProps & { theme: Theme }> = (props) => css`
  background: ${props.theme.components.Input.background.default};
  ${props.disabled !== true &&
  css`
    &:hover {
      background: ${props.theme.components.Input.background.hover};
    }
  `}
  color: ${props.theme.components.Input.text.default};
  &::placeholder {
    color: ${props.theme.components.Input.text.placeholder};
  }

  outline: none;
  border: 1px solid ${props.theme.components.Input.border.default};
  border-radius: 4px;
  padding: 8px 12px;
  ${props.hideBorders === true &&
  css`
    border-color: transparent;
  `}

  ${withGridStyles(props)};

  transition: ${props.theme.transition};

  &:focus {
    background-color: ${props.theme.components.Input.background.active};
    border-color: ${props.theme.components.Input.border.active};
  }
  ${props.error !== undefined &&
  css`
    border-color: ${props.theme.components.Input.border.error} !important;
  `}
  ${props.disabled === true &&
  css`
    color: ${props.theme.components.Input.text.disabled};
    background: ${props.theme.components.Input.background.disabled};
    border-color: ${props.theme.components.Input.border.disabled} !important;
  `}
`;

/**
 * Lightweight styled HTML input element
 *
 * @deprecated Use TextField instead.
 */
export const OldInputGoUseTextfield = styled.input<IProps>`
  ${(props) => withInputStyles(props)}
  border-color: ${(props) => props.borderColor};
`;

/**
 * Lightweight styled HTML textarea element
 */
export const Textarea = styled.textarea<IProps>`
  ${withInputStyles}
`;
