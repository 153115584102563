/* eslint-disable react/style-prop-object */
import React from "react";

export const Upload: React.FC = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle r="39.6" transform="matrix(1 0 0 -1 52 52)" stroke="#808689" strokeWidth="4" />
    <path
      d="M52 70.2V33.8M52 33.8L67.6 46.8M52 33.8L36.4 46.8"
      stroke="#808689"
      strokeWidth="4"
      strokeLinecap="square"
    />
  </svg>
);
