/* eslint-disable react/style-prop-object */
import React from "react";

export const Syncing: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 18V15.75H10.75V18H9.25V15.75H7.25V18H5.75V15.4501C5.23461 15.1867 4.81332 14.7654 4.54985 14.25H2V12.75H4.25V10.75H2V9.25H4.25V7.25H2V5.75H4.54985C4.81332 5.23461 5.23461 4.81332 5.75 4.54985V2H7.25V4.25H9.25V2H10.75V4.25H12.75V2H14.25V4.54985C14.7654 4.81332 15.1867 5.23462 15.4501 5.75H18V7.25H15.75V9.25H18V10.75H15.75V12.75H18V14.25H15.4501C15.1867 14.7654 14.7654 15.1867 14.25 15.4501V18H12.75ZM12.75 12.75H10.75V10.75H12.75V12.75ZM12.75 9.25H10.75V7.25H12.75V9.25ZM9.25 9.25V7.25H7.25V9.25H9.25ZM9.25 12.75V10.75H7.25V12.75H9.25Z"
      fill="url(#paint0_linear_979:13466)"
    />
    <path
      d="M13 5H7C5.89543 5 5 5.89543 5 7V13C5 14.1046 5.89543 15 7 15H13C14.1046 15 15 14.1046 15 13V7C15 5.89543 14.1046 5 13 5Z"
      fill="#1C1D21"
    />
    <path
      d="M12 7.5H8C7.72386 7.5 7.5 7.72386 7.5 8V12C7.5 12.2761 7.72386 12.5 8 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12V8C12.5 7.72386 12.2761 7.5 12 7.5Z"
      fill="#85868E"
    />
    <path
      d="M13 5H7C5.89543 5 5 5.89543 5 7V13C5 14.1046 5.89543 15 7 15H13C14.1046 15 15 14.1046 15 13V7C15 5.89543 14.1046 5 13 5Z"
      stroke="#363845"
      strokeWidth="1.5"
    />
    <path
      d="M5 12V13C5 14.1046 5.89543 15 7 15H10"
      stroke="url(#paint1_linear_979:13466)"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_979:13466"
        x1="-1"
        y1="-0.5"
        x2="18"
        y2="22.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1C1D21" />
        <stop offset="1" stopColor="#5E606E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_979:13466"
        x1="5"
        y1="12"
        x2="7.64706"
        y2="16.4118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB648A" />
        <stop offset="1" stopColor="#8358B1" />
      </linearGradient>
    </defs>
  </svg>
);
