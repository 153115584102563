import React, { useState } from "react";

import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { DataSourceLayout } from "~src/shared/dataSources/components/dataSourceLayout";
import { BASE_URL } from "~src/shared/env";
import { useEnv } from "~src/shared/env/useEnv";
import { useStepper } from "~src/shared/stepper/stepperContext";

import { BILLING_MANAGERS } from "../billingManagers";

interface IProps {
  redirectPath: IAllowedRedirectPath;
}

export const ConnectPayPal: React.FC<IProps> = ({ redirectPath }) => {
  const env = useEnv();
  const { removeTopOfStack } = useStepper();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const redirectToPayPal = () => {
    setIsRedirecting(true);
    const params = new URLSearchParams({
      scope:
        "openid+profile+email+address+https://uri.paypal.com/services/paypalattributes+https://uri.paypal.com/services/invoicing",
      redirect_uri: BASE_URL + `/api/paypal-oauth-callback?redirect_path=${redirectPath}`,
      response_type: "code",
      client_id: env.PAYPAL_CLIENT_ID,
    });

    const payPalBaseURL =
      env.PAYPAL_ENV === "sandbox" ? "https://www.sandbox.paypal.com" : "https://www.paypal.com";

    window.location.href = `${payPalBaseURL}/connect/?${params.toString()}`;
  };

  return (
    <DataSourceLayout
      dataSourceInfo={BILLING_MANAGERS.paypal}
      heading="Authenticate with OAuth"
      description="Securely connect your PayPal account via OAuth. You'll be directed to PayPal's website to sign in. Once you're done, you'll be redirected back to Pipe."
      secondaryButtonText="Back"
      secondaryButtonAction={removeTopOfStack}
      primaryButtonText={isRedirecting ? "Transferring you to PayPal..." : "Continue to PayPal"}
      primaryButtonAction={redirectToPayPal}
      isPrimaryButtonDisabled={isRedirecting}
    />
  );
};
