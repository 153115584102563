import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { UrlObject } from "url";

import { NavItemWrapper } from "./NavItemWrapper";

export interface IProps {
  className?: string;
  title?: string;
  shortcut?: string;
  icon?: React.ReactNode;
  /**
   * Whether to highlight the NavItem when the current path matches the href or
   * highlightPathIncludes. By default, this is true.
   */
  highlight?: boolean;
  highlightPathIncludes?: string[];
  onClick?: () => void;
  href?: string | UrlObject;
}

export const NavItem: React.FC<IProps> = ({
  className,
  title,
  shortcut,
  href,
  icon,
  highlight = true,
  highlightPathIncludes,
  onClick,
}: IProps) => {
  const router = useRouter();

  const isActive = (() => {
    if (!highlight) {
      return false;
    }
    if (highlightPathIncludes !== undefined) {
      for (const path of highlightPathIncludes) {
        if (router.asPath.includes(path)) {
          return true;
        }
      }
    }

    return router.asPath === href;
  })();

  const inner = (
    <NavItemWrapper
      className={className}
      title={title}
      shortcut={shortcut}
      onClick={onClick}
      isActive={isActive}
      icon={icon}
    />
  );

  if (href !== undefined) {
    return (
      <Link href={href} passHref>
        <a>{inner}</a>
      </Link>
    );
  }

  return inner;
};
