import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React from "react";

export interface ILoadingSpinnerProps {
  /** Any valid css for font-size (ex. 24px, 2em, etc.) */
  size?: string;
  ringWidth?: string;
  color?: string;
}

/** Defaults to size 24px and ringWidth 2px. */
export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
  size = "24px",
  ringWidth = "2px",
  color,
}) => (
  <Wrapper size={size} animate={animate} transition={transition}>
    <Circle size={size} ringWidth={ringWidth} color={color} />
  </Wrapper>
);

const Wrapper = styled(motion.div)<{ size: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;

type ICircleProps = {
  size: string;
  ringWidth: string;
  color?: string;
};

const Circle = styled.div<ICircleProps>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  border: ${(props) => `${props.ringWidth} solid transparent`};
  border-top: ${(props) => {
    const color = props.color ?? props.theme.components.LoadingSpinner.DefaultColor;
    return `${props.ringWidth} solid ${color}`;
  }};
`;

const animate = {
  rotate: 360,
};

const transition = {
  loop: Infinity,
  ease: "linear",
  duration: 1,
};
