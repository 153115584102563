import styled from "@emotion/styled";

export const V2Layout = styled.div`
  display: flex;

  height: 100vh;

  background-color: ${(props) => props.theme.components.App.background};
  transition: background-color 0.1s ease;
`;
