import { IImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import { IIntegrationType } from "~src/shared/types";

import { CSVLogo } from "../manual/CSVLogo";
import { Apple } from "./logos/Apple";
import { Chargebee } from "./logos/Chargebee";
import { Paypal } from "./logos/Paypal";
import { Recurly } from "./logos/Recurly";
import { StripeLogo } from "./logos/Stripe";

export type IBillingManagerKey =
  | "chargebee"
  | "stripe"
  | "gocardless"
  | "paypal"
  | "recurly"
  | "chargify"
  | "apple"
  | "csv";

export interface IBillingManager {
  key: IBillingManagerKey;
  name: string;
  subtitle: string;
  logo: IImageOrSVG;
  type: IIntegrationType;
}

export const BILLING_MANAGERS: Readonly<
  {
    [key in Exclude<IBillingManagerKey, "csv">]: IBillingManager;
  }
> = {
  chargebee: {
    key: "chargebee",
    name: "Chargebee",
    subtitle: "chargebee.com",
    logo: Chargebee,
    type: "billing_manager",
  },
  stripe: {
    key: "stripe",
    name: "Stripe",
    subtitle: "stripe.com",
    logo: StripeLogo,
    type: "billing_manager",
  },
  recurly: {
    key: "recurly",
    name: "Recurly",
    subtitle: "recurly.com",
    logo: Recurly,
    type: "billing_manager",
  },
  chargify: {
    key: "chargify",
    name: "Chargify",
    subtitle: "chargify.com",
    logo: "/static/assets/logos/chargify.png",
    type: "billing_manager",
  },
  apple: {
    key: "apple",
    name: "Apple App Store Connect",
    subtitle: "appstoreconnect.apple.com",
    logo: Apple,
    type: "billing_manager",
  },
  gocardless: {
    key: "gocardless",
    name: "GoCardless",
    subtitle: "gocardless.com",
    logo: "/static/assets/logos/gocardless.png",
    type: "billing_manager",
  },
  paypal: {
    key: "paypal",
    name: "PayPal",
    subtitle: "paypal.com",
    logo: Paypal,
    type: "billing_manager",
  },
};

export const CSV_BILLING_MANAGER: IBillingManager = {
  key: "csv",
  name: "CSV",
  subtitle: "Upload CSV",
  logo: CSVLogo,
  type: "billing_manager",
};
