import styled from "@emotion/styled";
import React from "react";

import { OldHeadingUseTextInstead } from "~src/designSystem/deprecated/Heading";
import { OldParagraphUseTextInstead } from "~src/designSystem/deprecated/Paragraph";
import { Flexbox } from "~src/designSystem/layout/Flexbox";

interface IProps {
  description: string | React.ReactNode;
  heading: string;
}

export const DataSourceHeadingDescriptionPairing: React.FC<IProps> = ({ description, heading }) => {
  return (
    <Flexbox>
      <OldHeadingUseTextInstead size="30px">{heading}</OldHeadingUseTextInstead>
      <Description color="muted">{description}</Description>
    </Flexbox>
  );
};

const Description = styled(OldParagraphUseTextInstead)`
  margin-top: ${({ theme }) => theme.spacing.md};
`;
