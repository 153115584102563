import { useEffect } from "react";

import { useAnalytics } from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource, IIntegrationType } from "~src/shared/types";

import {
  CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_GO_BACK_BUTTON_EVENT,
  CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_INSTRUCTIONS_BUTTON_EVENT,
  CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_CLOSE_EVENT,
  CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_PAGE_EVENT,
  CONNECT_BILLING_SOURCE_TO_CONNECTOR_GO_BACK_BUTTON_EVENT,
  CONNECT_BILLING_SOURCE_TO_CONNECTOR_INSTRUCTIONS_BUTTON_EVENT,
  CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_CLOSE,
  CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_PAGE_EVENT,
} from "./utils";

interface IProps {
  dataType: IIntegrationType;
  source: IConnectDataSourceFlowSource;
}

export const useDataSourceCredentialStepTracking = ({
  dataType,
  source,
}: IProps): {
  logOnGoBackEvent: () => void;
  logInstructionsButtonClickEvent: () => void;
  logModalCloseEvent: () => void;
} => {
  const { trackEvent, trackPage } = useAnalytics();

  useEffect(() => {
    const event =
      dataType === "billing_manager"
        ? CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_PAGE_EVENT[source]
        : dataType === "accounting"
        ? CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_PAGE_EVENT[source]
        : null;

    if (event !== null) {
      trackPage(event);
    }
  }, [dataType, source, trackPage]);

  const logOnGoBackEvent = () => {
    const event =
      dataType === "billing_manager"
        ? CONNECT_BILLING_SOURCE_TO_CONNECTOR_GO_BACK_BUTTON_EVENT[source]
        : dataType === "accounting"
        ? CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_GO_BACK_BUTTON_EVENT[source]
        : null;

    if (event !== null) {
      trackEvent(event);
    }
  };

  const logInstructionsButtonClickEvent = () => {
    const event =
      dataType === "billing_manager"
        ? CONNECT_BILLING_SOURCE_TO_CONNECTOR_INSTRUCTIONS_BUTTON_EVENT[source]
        : dataType === "accounting"
        ? CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_INSTRUCTIONS_BUTTON_EVENT[source]
        : null;
    if (event !== null) {
      trackEvent(event);
    }
  };

  const logModalCloseEvent = () => {
    const event =
      dataType === "billing_manager"
        ? CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_CLOSE[source]
        : dataType === "accounting"
        ? CONNECT_ACCOUNTING_SOURCE_TO_CONNECTOR_MODAL_CLOSE_EVENT[source]
        : null;
    if (event !== null) {
      trackEvent(event);
    }
  };

  return {
    logOnGoBackEvent,
    logInstructionsButtonClickEvent,
    logModalCloseEvent,
  };
};
