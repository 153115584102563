import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode, useMemo } from "react";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { IImageOrSVG, ImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import { textToColor } from "~src/shared/helpers";

export type IAvatarBadgeColor = "green" | "orange" | "red";

export interface IAvatarProps {
  className?: string;
  /**
   * Text to be displayed inside the icon (also determines the color)
   */
  text?: string;
  /**
   * TODO: Rename to statusDotColor.
   */
  color?: IAvatarBadgeColor;
  src?: IImageOrSVG;
  children?: ReactNode;
  // TODO(benny): standardize icon sizes
  size?: 40 | 36 | 30 | 24 | 18 | 14 | 20;
}

export const Avatar: React.FC<IAvatarProps> = (props) => {
  const { color, className, src, children, size = 24, text } = props;

  return (
    <WithStatusDot color={color}>
      <AvatarContainer
        size={size}
        text={text}
        className={className}
        alignItems="center"
        justifyContent="center"
      >
        {src !== undefined ? (
          <ImageOrSVG height={size} width={size} src={src} />
        ) : (
          <AvatarInner size={size}>{children}</AvatarInner>
        )}
      </AvatarContainer>
    </WithStatusDot>
  );
};

const AvatarInner = styled.span<{ size: number }>`
  & > svg {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;

const AvatarContainer = styled(Flexbox)<{
  size?: number;
  text?: string;
}>`
  border-radius: 100%;
  overflow: hidden;
  ${(props) =>
    props.size !== undefined &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
    `}

  background: ${(props) => props.text !== undefined && textToColor(props.text)};
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 7px 2px;

  flex-shrink: 0;
  span {
    line-height: 1 !important;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
    text-transform: capitalize;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

type IWithStatusDotProps = {
  color: IAvatarProps["color"];
  children: React.ReactElement;
};

const WithStatusDot = (props: IWithStatusDotProps) => {
  const theme = useTheme();

  const color = useMemo(() => {
    switch (true) {
      case props.color === "red":
        return theme.components.Avatar.StatusDot.red;
      case props.color === "green":
        return theme.components.Avatar.StatusDot.green;
      case props.color === "orange":
        return theme.components.Avatar.StatusDot.orange;
      default:
        return undefined;
    }
  }, [theme, props.color]);

  if (color === undefined) {
    return props.children;
  }

  return (
    <StatusDotWrapper>
      <StatusDot color={color} />
      {props.children}
    </StatusDotWrapper>
  );
};

const StatusDotWrapper = styled.div`
  position: relative;
`;

const StatusDot = styled.div<{ color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 100%;

  position: absolute;
  top: 0;
  margin-top: 2px;
  right: 3px;
  transform: translate(50%, -50%);
  transform-origin: 100% 0;

  background-color: ${(props) => props.color};
  box-shadow: rgb(14, 14, 17) 0px 0px 0px 2px;
`;
