/* eslint-disable react/style-prop-object */
import React from "react";

export const Apple: React.FC = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 45 45"
    xmlSpace="preserve"
  >
    <style type="text/css">{`.st0{fill:#23A0F9;}`}</style>
    <path
      className="st0"
      d="M15,32.3l-1.5,2.5c-0.5,0.9-1.7,1.2-2.6,0.7c-0.9-0.5-1.2-1.7-0.7-2.6l1.1-1.9l0.1-0.2c0.2-0.3,0.6-0.7,1.5-0.6
	c0,0,2.1,0.2,2.3,1.3C15.2,31.9,15.1,32.1,15,32.3z M35.6,25.9h-4.5c-0.3,0-0.4-0.1-0.5-0.2l-4.9-8.4l-0.3-0.4
	c-0.5-0.7-1.2,1.1-1.2,1.1c-0.9,2.1,0.1,4.4,0.5,5.2l6.7,11.7c0.5,0.9,1.7,1.2,2.6,0.7s1.2-1.7,0.7-2.6L33,30.1
	c0-0.1-0.1-0.3,0.3-0.3h2.3c0.7,0,1.3-0.4,1.7-1c0.4-0.6,0.3-1.3,0-1.9S36.3,25.9,35.6,25.9z M26.8,28.5c0,0,0.2,1.2-0.7,1.2H9.3
	c-0.7,0-1.3-0.4-1.7-1c-0.3-0.6-0.3-1.3,0-1.9c0.3-0.6,1-1,1.7-1h4.3c0.7,0,0.9-0.4,0.9-0.4l5.6-9.7c0.1-0.1,0.1-0.3,0-0.4l-1.9-3.2
	c-0.3-0.6-0.4-1.3,0-1.9c0.3-0.6,1-1,1.7-1c0.7,0,1.3,0.4,1.7,1l0.9,1.5l0.9-1.5C23.9,9.3,25.1,9,26,9.5s1.2,1.7,0.7,2.6l-7.8,13.5
	c0,0.1,0,0.2,0.2,0.2h4.7C23.7,25.9,26.4,26,26.8,28.5z"
    />
  </svg>
);
