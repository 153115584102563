import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React from "react";

interface IProps {
  children?: React.ReactNode;
  orientation?: "column" | "row" | "column-reverse" | "row-reverse";
  color?: string;
  style?: React.CSSProperties;
}

/** @deprecated use <Flexbox fullWidth /> instead */
export const OldFullWidthContainerDontUse = styled(motion.div)<IProps>`
  display: flex;
  flex-direction: ${(props) => props.orientation || "column"};

  width: 100%;

  background: ${(props) => props.color ?? "none"};
`;

OldFullWidthContainerDontUse.defaultProps = {
  exit: {},
};
