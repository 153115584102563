import React, { useState } from "react";
import toast from "react-hot-toast";

import { SUPPORT_EMAIL, SUPPORT_EMAIL_TEMPLATE_HREF } from "~src/shared/constants";
import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { vendorRequests } from "~src/vendor/requests";

import { DataSourceLayout } from "../../components/dataSourceLayout";
import { IAccountingPlatform } from "../accountingPlatforms";
import { configureCodatLinkURL } from "../codat";

interface IProps {
  redirectPath: IAllowedRedirectPath;
  platform: IAccountingPlatform;
}

export const ConnectAccountingRedirect: React.FC<IProps> = ({ redirectPath, platform }) => {
  const { removeTopOfStack } = useStepper();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const { key } = platform;

  if (key === "csv") {
    return null;
  }

  const redirectToCodat = async () => {
    setIsRedirecting(true);
    const res = await callRequest(
      vendorRequests.getCodatLinkURL({
        platformKey: key,
      }),
    );
    if (res.data?.linkURL !== undefined) {
      window.location.href = configureCodatLinkURL(
        res.data.publicID,
        res.data.linkURL,
        redirectPath,
      );
    } else {
      toast.error(
        <>
          There was an error in linking your account. Please contact support at{" "}
          <a href={SUPPORT_EMAIL_TEMPLATE_HREF}>{SUPPORT_EMAIL}</a>.
        </>,
      );
    }
  };

  return (
    <DataSourceLayout
      dataSourceInfo={platform}
      heading="Authenticate"
      description={`Securely connect your ${platform.name} account. You’ll be directed to ${platform.name} website to sign in. Once you’re done, you’ll be redirected back to Pipe.`}
      secondaryButtonText="Back"
      secondaryButtonAction={removeTopOfStack}
      primaryButtonText={
        isRedirecting ? `Transferring you to ${platform.name}...` : `Continue to ${platform.name}`
      }
      isPrimaryButtonDisabled={isRedirecting}
      primaryButtonAction={redirectToCodat}
    />
  );
};
