/* eslint-disable react/style-prop-object */
import React from "react";

export const CSVLogo: React.FC = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="17" fill="#48A94C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 18C24.55 18 25 17.55 25 17C25 16.45 24.55 16 24 16H23V13H24C24.55 13 25 12.55 25 12C25 11.45 24.55 11 24 11H23V10C23 9.45 22.55 9 22 9C21.45 9 21 9.45 21 10V11H18V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10V11H13V10C13 9.45 12.55 9 12 9C11.45 9 11 9.45 11 10V11H10C9.45 11 9 11.45 9 12C9 12.55 9.45 13 10 13H11V16H10C9.45 16 9 16.45 9 17C9 17.55 9.45 18 10 18H11V21H10C9.45 21 9 21.45 9 22C9 22.55 9.45 23 10 23H11V24C11 24.55 11.45 25 12 25C12.55 25 13 24.55 13 24V23H16V24C16 24.55 16.45 25 17 25C17.55 25 18 24.55 18 24V23H21V24C21 24.55 21.45 25 22 25C22.55 25 23 24.55 23 24V23H24C24.55 23 25 22.55 25 22C25 21.45 24.55 21 24 21H23V18H24ZM16 21H13V18H16V21ZM13 16H16V13H13V16ZM21 21H18V18H21V21ZM18 16H21V13H18V16Z"
      fill="white"
    />
  </svg>
);
